import React from "react";
import "./Kishan.css";
import "./banner.css";
// import AsForm from "./KmForm";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import { toast } from "react-toastify";
import Kmform from "./KmForm";

const KishanMorchaCont = () => {
  const [state, setState] = React.useState("");
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const states = [
    {
      _id: "66d733c8c7d0285f9611e91f",
      State: "Andaman & Nicobar Islands",
      createdAt: "2024-09-03T17:37:35.342Z",
    },
    {
      _id: "66d733c8c7d0285f9611e920",
      State: "Andhra Pradesh",
      createdAt: "2024-09-03T17:37:35.345Z",
    },
    {
      _id: "66d733c8c7d0285f9611e921",
      State: "Assam",
      createdAt: "2024-09-03T17:37:35.345Z",
    },
    {
      _id: "66d733c8c7d0285f9611e922",
      State: "Bihar",
      createdAt: "2024-09-03T17:37:35.346Z",
    },
    {
      _id: "66d733c8c7d0285f9611e923",
      State: "Chandigarh",
      createdAt: "2024-09-03T17:37:35.347Z",
    },
    {
      _id: "66d733c8c7d0285f9611e924",
      State: "Dadra & Nagar Haveli",
      createdAt: "2024-09-03T17:37:35.347Z",
    },
    {
      _id: "66d733c8c7d0285f9611e925",
      State: "Daman & Diu",
      createdAt: "2024-09-03T17:37:35.347Z",
    },
    {
      _id: "66d733c8c7d0285f9611e926",
      State: "Goa",
      createdAt: "2024-09-03T17:37:35.347Z",
    },
    {
      _id: "66d733c8c7d0285f9611e927",
      State: "Gujarat",
      createdAt: "2024-09-03T17:37:35.347Z",
    },
    {
      _id: "66d733c8c7d0285f9611e928",
      State: "Haryana",
      createdAt: "2024-09-03T17:37:35.348Z",
    },
    {
      _id: "66d733c8c7d0285f9611e929",
      State: "Himachal Pradesh",
      createdAt: "2024-09-03T17:37:35.348Z",
    },
    {
      _id: "66d733c8c7d0285f9611e92a",
      State: "Jharkhand",
      createdAt: "2024-09-03T17:37:35.348Z",
    },
    {
      _id: "66d733c8c7d0285f9611e92b",
      State: "Karnataka",
      createdAt: "2024-09-03T17:37:35.348Z",
    },
    {
      _id: "66d733c8c7d0285f9611e92c",
      State: "Kerala",
      createdAt: "2024-09-03T17:37:35.349Z",
    },
    {
      _id: "66d733c8c7d0285f9611e92d",
      State: "Lakshadweep",
      createdAt: "2024-09-03T17:37:35.349Z",
    },
    {
      _id: "66d733c8c7d0285f9611e92e",
      State: "Madhya Pradesh",
      createdAt: "2024-09-03T17:37:35.349Z",
    },
    {
      _id: "66d733c8c7d0285f9611e92f",
      State: "Maharashtra",
      createdAt: "2024-09-03T17:37:35.349Z",
    },
    {
      _id: "66d733c8c7d0285f9611e930",
      State: "Manipur",
      createdAt: "2024-09-03T17:37:35.349Z",
    },
    {
      _id: "66d733c8c7d0285f9611e931",
      State: "Meghalaya",
      createdAt: "2024-09-03T17:37:35.350Z",
    },
    {
      _id: "66d733c8c7d0285f9611e932",
      State: "Mizoram",
      createdAt: "2024-09-03T17:37:35.350Z",
    },
    {
      _id: "66d733c8c7d0285f9611e933",
      State: "Nagaland",
      createdAt: "2024-09-03T17:37:35.350Z",
    },
    {
      _id: "66d733c8c7d0285f9611e934",
      State: "NCT OF Delhi",
      createdAt: "2024-09-03T17:37:35.350Z",
    },
    {
      _id: "66d733c8c7d0285f9611e935",
      State: "Odisha",
      createdAt: "2024-09-03T17:37:35.350Z",
    },
    {
      _id: "66d733c8c7d0285f9611e936",
      State: "Puducherry",
      createdAt: "2024-09-03T17:37:35.350Z",
    },
    {
      _id: "66d733c8c7d0285f9611e937",
      State: "Punjab",
      createdAt: "2024-09-03T17:37:35.350Z",
    },
    {
      _id: "66d733c8c7d0285f9611e938",
      State: "Rajasthan",
      createdAt: "2024-09-03T17:37:35.350Z",
    },
    {
      _id: "66d733c8c7d0285f9611e939",
      State: "Sikkim",
      createdAt: "2024-09-03T17:37:35.350Z",
    },
    {
      _id: "66d733c8c7d0285f9611e93a",
      State: "Tamil Nadu",
      createdAt: "2024-09-03T17:37:35.351Z",
    },
    {
      _id: "66d733c8c7d0285f9611e93b",
      State: "Telangana",
      createdAt: "2024-09-03T17:37:35.351Z",
    },
    {
      _id: "66d733c8c7d0285f9611e93c",
      State: "Tripura",
      createdAt: "2024-09-03T17:37:35.351Z",
    },
    {
      _id: "66d733c8c7d0285f9611e93d",
      State: "Uttar Pradesh",
      createdAt: "2024-09-03T17:37:35.351Z",
    },
    {
      _id: "66d733c8c7d0285f9611e93e",
      State: "Uttarakhand",
      createdAt: "2024-09-03T17:37:35.351Z",
    },
    {
      _id: "66d733c8c7d0285f9611e93f",
      State: "West Bengal",
      createdAt: "2024-09-03T17:37:35.351Z",
    },
    {
      _id: "66d733c8c7d0285f9611e940",
      State: "Jammu & Kashmir",
      createdAt: "2024-09-03T17:37:35.351Z",
    },
    {
      _id: "66d7348fc7d0285f96123f95",
      state: "Arunachal Pradesh",
      createdAt: "2024-09-03T17:37:35.351Z",
    },
    {
      _id: "66d734e5c7d0285f96126e4d",
      state: "Chhattisgarh",
      createdAt: "2024-09-03T17:37:35.352Z",
    },
  ];

  const handleChange = (event) => {
    // console.log(event.target.value);
    toast.success(`${event.target.value} selected`);
    setState(event.target.value);
  };
  return (
    <div>
      <div className="kishan-Box">
        {/* <div className="banner-as">
          <div >
            <img src={modiji} alt="" className="imgleader"/>
          </div>
          <div>
            <div>
              <div>
                <img src={logo} alt="" className="logoas"/>
                <img src={text} alt="" className="logoas"/>
              </div>
              <div></div>
            </div>
          </div>
          <div>
            <img src={leader2} alt="" className="imgleader"/>
          </div>
        </div> */}

        <Box
          sx={{ minWidth: 120, bgcolor: "white" }}
          component={"div"}
          className="selectboxkisan"
        >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Select Your State
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={state}
              label="अपने राज्य का चयन"
              onChange={handleChange}
            >
              <MenuItem value={"Andaman & Nicobar Islands"}>
                Andaman & Nicobar Islands
              </MenuItem>
              <MenuItem value={"Andhra Pradesh"}>Andhra Pradesh</MenuItem>
              <MenuItem value={"Assam"}>Assam</MenuItem>
              <MenuItem value={"Bihar"}>Bihar</MenuItem>
              <MenuItem value={"Chandigarh"}>Chandigarh</MenuItem>
              <MenuItem value={"Dadra & Nagar Haveli"}>
                Dadra & Nagar Haveli
              </MenuItem>
              <MenuItem value={"Daman & Diu"}>Daman & Diu</MenuItem>
              <MenuItem value={"Goa"}>Goa</MenuItem>
              <MenuItem value={"Gujarat"}>Gujarat</MenuItem>
              <MenuItem value={"Haryana"}>Haryana</MenuItem>
              <MenuItem value={"Himachal Pradesh"}>Himachal Pradesh</MenuItem>
              <MenuItem value={"Jammu & Kashmir"}>Jammu & Kashmir</MenuItem>
              <MenuItem value={"Jharkhand"}>Jharkhand</MenuItem>
              <MenuItem value={"Karnataka"}>Karnataka</MenuItem>
              <MenuItem value={"Kerala"}>Kerala</MenuItem>
              <MenuItem value={"Lakshadweep"}>Lakshadweep </MenuItem>
              <MenuItem value={"Madhya Pradesh"}>Madhya Pradesh </MenuItem>
              <MenuItem value={"Maharashtra"}>Maharashtra </MenuItem>
              <MenuItem value={"Manipur"}>Manipur </MenuItem>
              <MenuItem value={"Meghalaya"}>Meghalaya </MenuItem>
              <MenuItem value={"Mizoram"}>Mizoram </MenuItem>
              <MenuItem value={"Nagaland"}>Nagaland </MenuItem>
              <MenuItem value={"NCT OF Delhi"}>NCT Of Delhi </MenuItem>
              <MenuItem value={"Odisha"}>Odisha </MenuItem>
              <MenuItem value={"Puducherry"}>Puducherry </MenuItem>
              <MenuItem value={"Punjab"}>Punjab</MenuItem>
              <MenuItem value={"Rajasthan"}>Rajasthan</MenuItem>
              <MenuItem value={"Sikkim"}>Sikkim </MenuItem>
              <MenuItem value={"Tamil Nadu"}>Tamil Nadu </MenuItem>
              <MenuItem value={"Telangana"}>Telangana </MenuItem>
              <MenuItem value={"Tripura"}>Tripura </MenuItem>
              <MenuItem value={"Uttar Pradesh"}>Uttar Pradesh </MenuItem>
              <MenuItem value={"Uttarakhand"}>Uttarakhand </MenuItem>
              <MenuItem value={"West Bengal"}>West Bengal </MenuItem>

              {/* <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
            </Select>
          </FormControl>
        </Box>
      </div>

<>

{isSmallScreen && (
       <Box
       sx={{ minWidth: 120, bgcolor: "white",marginTop:'20px',marginBottom:'20px',padding:'10px' }}
       component={"div"}
      
     >
       <FormControl fullWidth>
         <InputLabel id="demo-simple-select-label">
           Select Your State
         </InputLabel>
         <Select
           labelId="demo-simple-select-label"
           id="demo-simple-select"
           value={state}
           label="अपने राज्य का चयन"
           onChange={handleChange}
         >
           <MenuItem value={"Andaman & Nicobar Islands"}>
             Andaman & Nicobar Islands
           </MenuItem>
           <MenuItem value={"Andhra Pradesh"}>Andhra Pradesh</MenuItem>
           <MenuItem value={"Assam"}>Assam</MenuItem>
           <MenuItem value={"Bihar"}>Bihar</MenuItem>
           <MenuItem value={"Chandigarh"}>Chandigarh</MenuItem>
           <MenuItem value={"Dadra & Nagar Haveli"}>
             Dadra & Nagar Haveli
           </MenuItem>
           <MenuItem value={"Daman & Diu"}>Daman & Diu</MenuItem>
           <MenuItem value={"Goa"}>Goa</MenuItem>
           <MenuItem value={"Gujarat"}>Gujarat</MenuItem>
           <MenuItem value={"Haryana"}>Haryana</MenuItem>
           <MenuItem value={"Himachal Pradesh"}>Himachal Pradesh</MenuItem>
           <MenuItem value={"Jammu & Kashmir"}>Jammu & Kashmir</MenuItem>
           <MenuItem value={"Jharkhand"}>Jharkhand</MenuItem>
           <MenuItem value={"Karnataka"}>Karnataka</MenuItem>
           <MenuItem value={"Kerala"}>Kerala</MenuItem>
           <MenuItem value={"Lakshadweep"}>Lakshadweep </MenuItem>
           <MenuItem value={"Madhya Pradesh"}>Madhya Pradesh </MenuItem>
           <MenuItem value={"Maharashtra"}>Maharashtra </MenuItem>
           <MenuItem value={"Manipur"}>Manipur </MenuItem>
           <MenuItem value={"Meghalaya"}>Meghalaya </MenuItem>
           <MenuItem value={"Mizoram"}>Mizoram </MenuItem>
           <MenuItem value={"Nagaland"}>Nagaland </MenuItem>
           <MenuItem value={"NCT OF Delhi"}>NCT Of Delhi </MenuItem>
           <MenuItem value={"Odisha"}>Odisha </MenuItem>
           <MenuItem value={"Puducherry"}>Puducherry </MenuItem>
           <MenuItem value={"Punjab"}>Punjab</MenuItem>
           <MenuItem value={"Rajasthan"}>Rajasthan</MenuItem>
           <MenuItem value={"Sikkim"}>Sikkim </MenuItem>
           <MenuItem value={"Tamil Nadu"}>Tamil Nadu </MenuItem>
           <MenuItem value={"Telangana"}>Telangana </MenuItem>
           <MenuItem value={"Tripura"}>Tripura </MenuItem>
           <MenuItem value={"Uttar Pradesh"}>Uttar Pradesh </MenuItem>
           <MenuItem value={"Uttarakhand"}>Uttarakhand </MenuItem>
           <MenuItem value={"West Bengal"}>West Bengal </MenuItem>

           {/* <MenuItem value={20}>Twenty</MenuItem>
       <MenuItem value={30}>Thirty</MenuItem> */}
         </Select>
       </FormControl>
     </Box>
      )}
      
      
      </>


      <div style={{ backgroundColor: "whitesmoke", textAlign: "center" }}>
        {state ? (
          <Kmform prop={state} setState={setState} />
        ) : (
          <>
            <div
              style={{
                background: "black",
                opacity: "0.5",
                filter: "grayscale(100%)",
              }}
              onClick={() => {
                toast.dark("Please select your State!");
              }}
            >
              <Button disabled={true} style={{ textTransform: "none" }}>
                {" "}
                <Kmform prop={state} />
              </Button>
            </div>{" "}
          </>
        )}
      </div>
    </div>
  );
};

export default KishanMorchaCont;
