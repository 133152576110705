import { Route, Routes } from "react-router-dom";

import Mainpage from "../pages/main/Mainpage";
import AlapSakhyank from "../pages/alapsakhyank-morcha/AlapSakhyank";
import KishanMorchaCont from "../components/kishanMorcha/kishanMorchaCont";
import ObcMorchaContent from "../components/obcMorchaContent/ObcMorchaCont";
// import PDFFile from "../pages/pdfview/PdfFile";

export default function AppRoutes() {
 

  return (
    <Routes>
        <Route index element={<Mainpage />} />
      <Route path="/alapsakhyank-morcha" element={<AlapSakhyank/>} />
      <Route path="/kisan-morcha" element={<KishanMorchaCont/>} />
      <Route path="/obc-morcha" element={<ObcMorchaContent/>} />
    

      <Route path="*" element={<h1>404 Page not found</h1>} />
    </Routes>
  );
}
