import React from 'react'
import "./mainPageCo.css"

const MainPageCo = () => {
  return (
    <div>
        <div className="background-image-div-home">
  <div className="home-button-morcha">
    <div>
      <button><a href>Morcha</a></button>
    </div>
    <div>
      <button><a href>Morcha</a></button>
    </div>
    <div>
      <button><a href>Morcha</a></button>
    </div>
  </div>
</div>

    </div>
  )
}

export default MainPageCo