import React from 'react'
import AlapsakhyankCont from '../../components/AlapsakhyankContent/AlapsakhyankCont'

const AlapSakhyank = () => {
  return (
    <div>
        <AlapsakhyankCont/>
    </div>
  )
}

export default AlapSakhyank