import {
    Box,
    Button,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    useMediaQuery,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { Formik } from "formik";
  import * as Yup from "yup";
  import { toast } from "react-toastify";
  
  const AsForm = ({ prop ,setState}) => {
    const [loksabhaseat, setLoksabhaseat] = useState([]);
    const [loksabha, setLoksabha] = useState(""); // State for Loksabha selection
    const [selectedReligion, setSelectedReligion] = useState(""); // State for Religion selection
    const [loading, setLoading] = useState(false); // Loading state
    const isMobile = useMediaQuery("(max-width:600px)");
    const [religion] = useState([
      { religionName: "Muslim" },
      { religionName: "Jain" },
      { religionName: "Sikh" },
      { religionName: "Christian" },
      { religionName: "Parsi" },
      { religionName: "Buddhist" },
      { religionName: "Jew" },
      { religionName: "Others" },
    ]);
  const data = [
    {
      _id: "66d72401c7d0285f960ae1dd",
      State: "Andaman & Nicobar Islands",
      Constituency: "Andaman & Nicobar Islands",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1de",
      State: "Andhra Pradesh",
      Constituency: "Aruku",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1df",
      State: "Andhra Pradesh",
      Constituency: "Srikakulam",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1e0",
      State: "Andhra Pradesh",
      Constituency: "Vizianagaram",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1e1",
      State: "Andhra Pradesh",
      Constituency: "Visakhapatnam",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1e2",
      State: "Andhra Pradesh",
      Constituency: "Anakapalli",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1e3",
      State: "Andhra Pradesh",
      Constituency: "Kakinada",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1e4",
      State: "Andhra Pradesh",
      Constituency: "Amalapuram",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1e5",
      State: "Andhra Pradesh",
      Constituency: "Rajahmundry",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1e6",
      State: "Andhra Pradesh",
      Constituency: "Narsapuram",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1e7",
      State: "Andhra Pradesh",
      Constituency: "Eluru",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1e8",
      State: "Andhra Pradesh",
      Constituency: "Machilipatnam",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1e9",
      State: "Andhra Pradesh",
      Constituency: "Vijayawada",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1ea",
      State: "Andhra Pradesh",
      Constituency: "Guntur",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1eb",
      State: "Andhra Pradesh",
      Constituency: "Narasaraopet",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1ec",
      State: "Andhra Pradesh",
      Constituency: "Bapatla",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1ed",
      State: "Andhra Pradesh",
      Constituency: "Ongole",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1ee",
      State: "Andhra Pradesh",
      Constituency: "Nandyal",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1ef",
      State: "Andhra Pradesh",
      Constituency: "Kurnool",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1f0",
      State: "Andhra Pradesh",
      Constituency: "Anantapur",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1f1",
      State: "Andhra Pradesh",
      Constituency: "Hindupur",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1f2",
      State: "Andhra Pradesh",
      Constituency: "Kadapa",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1f3",
      State: "Andhra Pradesh",
      Constituency: "Nellore",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1f4",
      State: "Andhra Pradesh",
      Constituency: "Tirupati",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1f5",
      State: "Andhra Pradesh",
      Constituency: "Rajampet",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1f6",
      State: "Andhra Pradesh",
      Constituency: "Chittoor",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1f7",
      State: "Arunachal Pradesh",
      Constituency: "Arunachal West",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1f8",
      State: "Arunachal Pradesh",
      Constituency: "Arunachal East",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1f9",
      State: "Assam",
      Constituency: "Karimganj",
      createdAt: "2024-09-03T18:53:02.789Z",
    },
    {
      _id: "66d72401c7d0285f960ae1fa",
      State: "Assam",
      Constituency: "Silchar",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae1fb",
      State: "Assam",
      Constituency: "Autonomous District",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae1fc",
      State: "Assam",
      Constituency: "Dhubri",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae1fd",
      State: "Assam",
      Constituency: "Kokrajhar",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae1fe",
      State: "Assam",
      Constituency: "Barpeta",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae1ff",
      State: "Assam",
      Constituency: "Gauhati",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae200",
      State: "Assam",
      Constituency: "Mangaldoi",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae201",
      State: "Assam",
      Constituency: "Tezpur",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae202",
      State: "Assam",
      Constituency: "Nowgong",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae203",
      State: "Assam",
      Constituency: "Kaliabor",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae204",
      State: "Assam",
      Constituency: "Jorhat",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae205",
      State: "Assam",
      Constituency: "Dibrugarh",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae206",
      State: "Assam",
      Constituency: "Lakhimpur",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae207",
      State: "Bihar",
      Constituency: "Valmiki Nagar",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae208",
      State: "Bihar",
      Constituency: "Paschim Champaran",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae209",
      State: "Bihar",
      Constituency: "Purvi Champaran",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae20a",
      State: "Bihar",
      Constituency: "Sheohar",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae20b",
      State: "Bihar",
      Constituency: "Sitamarhi",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae20c",
      State: "Bihar",
      Constituency: "Madhubani",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae20d",
      State: "Bihar",
      Constituency: "Jhanjharpur",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae20e",
      State: "Bihar",
      Constituency: "Supaul",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae20f",
      State: "Bihar",
      Constituency: "Araria",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae210",
      State: "Bihar",
      Constituency: "Kishanganj",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae211",
      State: "Bihar",
      Constituency: "Katihar",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae212",
      State: "Bihar",
      Constituency: "Purnia",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae213",
      State: "Bihar",
      Constituency: "Madhepura",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae214",
      State: "Bihar",
      Constituency: "Darbhanga",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae215",
      State: "Bihar",
      Constituency: "Muzaffarpur",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae216",
      State: "Bihar",
      Constituency: "Vaishali",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae217",
      State: "Bihar",
      Constituency: "Gopalganj",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae218",
      State: "Bihar",
      Constituency: "Siwan",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae219",
      State: "Bihar",
      Constituency: "Maharajganj",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae21a",
      State: "Bihar",
      Constituency: "Saran",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae21b",
      State: "Bihar",
      Constituency: "Hajipur",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae21c",
      State: "Bihar",
      Constituency: "Ujiarpur",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae21d",
      State: "Bihar",
      Constituency: "Samastipur",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae21e",
      State: "Bihar",
      Constituency: "Begusarai",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae21f",
      State: "Bihar",
      Constituency: "Khagaria",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae220",
      State: "Bihar",
      Constituency: "Bhagalpur",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae221",
      State: "Bihar",
      Constituency: "Banka",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae222",
      State: "Bihar",
      Constituency: "Munger",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae223",
      State: "Bihar",
      Constituency: "Nalanda",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae224",
      State: "Bihar",
      Constituency: "Patna Sahib",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae225",
      State: "Bihar",
      Constituency: "Pataliputra",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae226",
      State: "Bihar",
      Constituency: "Arrah",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae227",
      State: "Bihar",
      Constituency: "Buxar",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae228",
      State: "Bihar",
      Constituency: "Sasaram",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae229",
      State: "Bihar",
      Constituency: "Karakat",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae22a",
      State: "Bihar",
      Constituency: "Jahanabad",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae22b",
      State: "Bihar",
      Constituency: "Aurangabad",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae22c",
      State: "Bihar",
      Constituency: "Gaya",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae22d",
      State: "Bihar",
      Constituency: "Nawada",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae22e",
      State: "Bihar",
      Constituency: "Jamui",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae22f",
      State: "Chandigarh",
      Constituency: "Chandigarh",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae230",
      State: "Chhattisgarh",
      Constituency: "Sarguja",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae231",
      State: "Chhattisgarh",
      Constituency: "Raigarh",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae232",
      State: "Chhattisgarh",
      Constituency: "Janjgir-Champa",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae233",
      State: "Chhattisgarh",
      Constituency: "Korba",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae234",
      State: "Chhattisgarh",
      Constituency: "Bilaspur",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae235",
      State: "Chhattisgarh",
      Constituency: "Rajnandgaon",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae236",
      State: "Chhattisgarh",
      Constituency: "Durg",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae237",
      State: "Chhattisgarh",
      Constituency: "Raipur",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae238",
      State: "Chhattisgarh",
      Constituency: "Mahasamund",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae239",
      State: "Chhattisgarh",
      Constituency: "Bastar",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae23a",
      State: "Chhattisgarh",
      Constituency: "Kanker",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae23b",
      State: "Dadra & Nagar Haveli",
      Constituency: "Dadar & Nagar Haveli",
      createdAt: "2024-09-03T18:53:02.790Z",
    },
    {
      _id: "66d72401c7d0285f960ae23c",
      State: "Daman & Diu",
      Constituency: "Daman & Diu",
      createdAt: "2024-09-03T18:53:02.791Z",
    },
    {
      _id: "66d72401c7d0285f960ae23d",
      State: "Goa",
      Constituency: "North Goa",
      createdAt: "2024-09-03T18:53:02.791Z",
    },
    {
      _id: "66d72401c7d0285f960ae23e",
      State: "Goa",
      Constituency: "South Goa",
      createdAt: "2024-09-03T18:53:02.791Z",
    },
    {
      _id: "66d72401c7d0285f960ae23f",
      State: "Gujarat",
      Constituency: "Kachchh",
      createdAt: "2024-09-03T18:53:02.791Z",
    },
    {
      _id: "66d72401c7d0285f960ae240",
      State: "Gujarat",
      Constituency: "Banaskantha",
      createdAt: "2024-09-03T18:53:02.791Z",
    },
    {
      _id: "66d72401c7d0285f960ae241",
      State: "Gujarat",
      Constituency: "Patan",
      createdAt: "2024-09-03T18:53:02.791Z",
    },
    {
      _id: "66d72401c7d0285f960ae242",
      State: "Gujarat",
      Constituency: "Mahesana",
      createdAt: "2024-09-03T18:53:02.791Z",
    },
    {
      _id: "66d72401c7d0285f960ae243",
      State: "Gujarat",
      Constituency: "Sabarkantha",
      createdAt: "2024-09-03T18:53:02.791Z",
    },
    {
      _id: "66d72401c7d0285f960ae244",
      State: "Gujarat",
      Constituency: "Gandhinagar",
      createdAt: "2024-09-03T18:53:02.791Z",
    },
    {
      _id: "66d72401c7d0285f960ae245",
      State: "Gujarat",
      Constituency: "Ahmedabad East",
      createdAt: "2024-09-03T18:53:02.791Z",
    },
    {
      _id: "66d72401c7d0285f960ae246",
      State: "Gujarat",
      Constituency: "Ahmedabad West",
      createdAt: "2024-09-03T18:53:02.791Z",
    },
    {
      _id: "66d72401c7d0285f960ae247",
      State: "Gujarat",
      Constituency: "Surendranagar",
      createdAt: "2024-09-03T18:53:02.791Z",
    },
    {
      _id: "66d72401c7d0285f960ae248",
      State: "Gujarat",
      Constituency: "Rajkot",
      createdAt: "2024-09-03T18:53:02.791Z",
    },
    {
      _id: "66d72401c7d0285f960ae249",
      State: "Gujarat",
      Constituency: "Porbandar",
      createdAt: "2024-09-03T18:53:02.791Z",
    },
    {
      _id: "66d72401c7d0285f960ae24a",
      State: "Gujarat",
      Constituency: "Jamnagar",
      createdAt: "2024-09-03T18:53:02.791Z",
    },
    {
      _id: "66d72401c7d0285f960ae24b",
      State: "Gujarat",
      Constituency: "Junagadh",
      createdAt: "2024-09-03T18:53:02.791Z",
    },
    {
      _id: "66d72401c7d0285f960ae24c",
      State: "Gujarat",
      Constituency: "Amreli",
      createdAt: "2024-09-03T18:53:02.791Z",
    },
    {
      _id: "66d72401c7d0285f960ae24d",
      State: "Gujarat",
      Constituency: "Bhavnagar",
      createdAt: "2024-09-03T18:53:02.791Z",
    },
    {
      _id: "66d72401c7d0285f960ae24e",
      State: "Gujarat",
      Constituency: "Anand",
      createdAt: "2024-09-03T18:53:02.791Z",
    },
    {
      _id: "66d72401c7d0285f960ae24f",
      State: "Gujarat",
      Constituency: "Kheda",
      createdAt: "2024-09-03T18:53:02.791Z",
    },
    {
      _id: "66d72401c7d0285f960ae250",
      State: "Gujarat",
      Constituency: "Panchmahal",
      createdAt: "2024-09-03T18:53:02.791Z",
    },
    {
      _id: "66d72401c7d0285f960ae251",
      State: "Gujarat",
      Constituency: "Dahod",
      createdAt: "2024-09-03T18:53:02.791Z",
    },
    {
      _id: "66d72401c7d0285f960ae252",
      State: "Gujarat",
      Constituency: "Vadodara",
      createdAt: "2024-09-03T18:53:02.791Z",
    },
    {
      _id: "66d72401c7d0285f960ae253",
      State: "Gujarat",
      Constituency: "Chhota Udaipur",
      createdAt: "2024-09-03T18:53:02.792Z",
    },
    {
      _id: "66d72401c7d0285f960ae254",
      State: "Gujarat",
      Constituency: "Bharuch",
      createdAt: "2024-09-03T18:53:02.792Z",
    },
    {
      _id: "66d72401c7d0285f960ae255",
      State: "Gujarat",
      Constituency: "Bardoli",
      createdAt: "2024-09-03T18:53:02.792Z",
    },
    {
      _id: "66d72401c7d0285f960ae256",
      State: "Gujarat",
      Constituency: "Surat",
      createdAt: "2024-09-03T18:53:02.792Z",
    },
    {
      _id: "66d72401c7d0285f960ae257",
      State: "Gujarat",
      Constituency: "Navsari",
      createdAt: "2024-09-03T18:53:02.792Z",
    },
    {
      _id: "66d72401c7d0285f960ae258",
      State: "Gujarat",
      Constituency: "Valsad",
      createdAt: "2024-09-03T18:53:02.792Z",
    },
    {
      _id: "66d72401c7d0285f960ae259",
      State: "Haryana",
      Constituency: "Ambala",
      createdAt: "2024-09-03T18:53:02.792Z",
    },
    {
      _id: "66d72401c7d0285f960ae25a",
      State: "Haryana",
      Constituency: "Kurukshetra",
      createdAt: "2024-09-03T18:53:02.792Z",
    },
    {
      _id: "66d72401c7d0285f960ae25b",
      State: "Haryana",
      Constituency: "Sirsa",
      createdAt: "2024-09-03T18:53:02.792Z",
    },
    {
      _id: "66d72401c7d0285f960ae25c",
      State: "Haryana",
      Constituency: "Hisar",
      createdAt: "2024-09-03T18:53:02.792Z",
    },
    {
      _id: "66d72401c7d0285f960ae25e",
      State: "Haryana",
      Constituency: "Karnal",
      createdAt: "2024-09-03T18:53:02.792Z",
    },
    {
      _id: "66d72401c7d0285f960ae25f",
      State: "Haryana",
      Constituency: "Sonipat",
      createdAt: "2024-09-03T18:53:02.792Z",
    },
    {
      _id: "66d72401c7d0285f960ae260",
      State: "Haryana",
      Constituency: "Rohtak",
      createdAt: "2024-09-03T18:53:02.792Z",
    },
    {
      _id: "66d72401c7d0285f960ae261",
      State: "Haryana",
      Constituency: "Bhiwani-Mahendragarh",
      createdAt: "2024-09-03T18:53:02.792Z",
    },
    {
      _id: "66d72401c7d0285f960ae262",
      State: "Haryana",
      Constituency: "Gurgaon",
      createdAt: "2024-09-03T18:53:02.792Z",
    },
    {
      _id: "66d72401c7d0285f960ae263",
      State: "Haryana",
      Constituency: "Faridabad",
      createdAt: "2024-09-03T18:53:02.792Z",
    },
    {
      _id: "66d72401c7d0285f960ae264",
      State: "Himachal Pradesh",
      Constituency: "Kangra",
      createdAt: "2024-09-03T18:53:02.792Z",
    },
    {
      _id: "66d72401c7d0285f960ae265",
      State: "Himachal Pradesh",
      Constituency: "Mandi",
      createdAt: "2024-09-03T18:53:02.792Z",
    },
    {
      _id: "66d72401c7d0285f960ae266",
      State: "Himachal Pradesh",
      Constituency: "Hamirpur",
      createdAt: "2024-09-03T18:53:02.792Z",
    },
    {
      _id: "66d72401c7d0285f960ae267",
      State: "Himachal Pradesh",
      Constituency: "Shimla",
      createdAt: "2024-09-03T18:53:02.792Z",
    },
    {
      _id: "66d72401c7d0285f960ae268",
      State: "Jharkhand",
      Constituency: "Rajmahal",
      createdAt: "2024-09-03T18:53:02.792Z",
    },
    {
      _id: "66d72401c7d0285f960ae269",
      State: "Jharkhand",
      Constituency: "Dumka",
      createdAt: "2024-09-03T18:53:02.792Z",
    },
    {
      _id: "66d72401c7d0285f960ae26a",
      State: "Jharkhand",
      Constituency: "Godda",
      createdAt: "2024-09-03T18:53:02.792Z",
    },
    {
      _id: "66d72401c7d0285f960ae26b",
      State: "Jharkhand",
      Constituency: "Chatra",
      createdAt: "2024-09-03T18:53:02.792Z",
    },
    {
      _id: "66d72401c7d0285f960ae26c",
      State: "Jharkhand",
      Constituency: "Kodarma",
      createdAt: "2024-09-03T18:53:02.792Z",
    },
    {
      _id: "66d72401c7d0285f960ae26d",
      State: "Jharkhand",
      Constituency: "Giridih",
      createdAt: "2024-09-03T18:53:02.792Z",
    },
    {
      _id: "66d72401c7d0285f960ae26e",
      State: "Jharkhand",
      Constituency: "Dhanbad",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae26f",
      State: "Jharkhand",
      Constituency: "Ranchi",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae270",
      State: "Jharkhand",
      Constituency: "Jamshedpur",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae271",
      State: "Jharkhand",
      Constituency: "Singhbhum",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae272",
      State: "Jharkhand",
      Constituency: "Khunti",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae273",
      State: "Jharkhand",
      Constituency: "Lohardaga",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae274",
      State: "Jharkhand",
      Constituency: "Palamau",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae275",
      State: "Jharkhand",
      Constituency: "Hazaribagh",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae276",
      State: "Karnataka",
      Constituency: "Chikkodi",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae277",
      State: "Karnataka",
      Constituency: "Belgaum",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae278",
      State: "Karnataka",
      Constituency: "Bagalkot",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae279",
      State: "Karnataka",
      Constituency: "Bijapur",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae27a",
      State: "Karnataka",
      Constituency: "Gulbarga",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae27b",
      State: "Karnataka",
      Constituency: "Raichur",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae27c",
      State: "Karnataka",
      Constituency: "Bidar",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae27d",
      State: "Karnataka",
      Constituency: "Koppal",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae27e",
      State: "Karnataka",
      Constituency: "Bellary",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae27f",
      State: "Karnataka",
      Constituency: "Haveri",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae280",
      State: "Karnataka",
      Constituency: "Dharwad",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae281",
      State: "Karnataka",
      Constituency: "Uttara Kannada",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae282",
      State: "Karnataka",
      Constituency: "Davanagere",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae283",
      State: "Karnataka",
      Constituency: "Shimoga",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae284",
      State: "Karnataka",
      Constituency: "Udupi Chikmagalur",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae285",
      State: "Karnataka",
      Constituency: "Hassan",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae286",
      State: "Karnataka",
      Constituency: "Dakshina Kannada",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae287",
      State: "Karnataka",
      Constituency: "Chitradurga",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae288",
      State: "Karnataka",
      Constituency: "Tumkur",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae289",
      State: "Karnataka",
      Constituency: "Mandya",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae28a",
      State: "Karnataka",
      Constituency: "Mysore",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae28b",
      State: "Karnataka",
      Constituency: "Chamarajanagar",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae28c",
      State: "Karnataka",
      Constituency: "Bangalore Rural",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae28d",
      State: "Karnataka",
      Constituency: "Bangalore North",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae28e",
      State: "Karnataka",
      Constituency: "Bangalore Central",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae28f",
      State: "Karnataka",
      Constituency: "Bangalore South",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae290",
      State: "Karnataka",
      Constituency: "Chikkballapur",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae291",
      State: "Karnataka",
      Constituency: "Kolar",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae292",
      State: "Kerala",
      Constituency: "Kasaragod",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae293",
      State: "Kerala",
      Constituency: "Kannur",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae294",
      State: "Kerala",
      Constituency: "Vadakara",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae295",
      State: "Kerala",
      Constituency: "Wayanad",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae296",
      State: "Kerala",
      Constituency: "Kozhikode",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae297",
      State: "Kerala",
      Constituency: "Malappuram",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae298",
      State: "Kerala",
      Constituency: "Ponnani",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae299",
      State: "Kerala",
      Constituency: "Palakkad",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae29a",
      State: "Kerala",
      Constituency: "Alathur",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae29b",
      State: "Kerala",
      Constituency: "Thrissur",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae29c",
      State: "Kerala",
      Constituency: "Chalakudy",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae29d",
      State: "Kerala",
      Constituency: "Ernakulam",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae2a0",
      State: "Kerala",
      Constituency: "Idukki",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae2a1",
      State: "Kerala",
      Constituency: "Kottayam",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae2a2",
      State: "Kerala",
      Constituency: "Alappuzha",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae2a3",
      State: "Kerala",
      Constituency: "Mavelikkara",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae2a4",
      State: "Kerala",
      Constituency: "Pathanamthitta",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae2a5",
      State: "Kerala",
      Constituency: "Kollam",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae2a6",
      State: "Kerala",
      Constituency: "Attingal",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae2a7",
      State: "Kerala",
      Constituency: "Thiruvananthapuram",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae2a8",
      State: "Lakshadweep",
      Constituency: "Lakshadweep",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae2a9",
      State: "Madhya Pradesh",
      Constituency: "Morena",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae2aa",
      State: "Madhya Pradesh",
      Constituency: "Bhind",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae2ab",
      State: "Madhya Pradesh",
      Constituency: "Gwalior",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae2ac",
      State: "Madhya Pradesh",
      Constituency: "Guna",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae2ad",
      State: "Madhya Pradesh",
      Constituency: "Sagar",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae2ae",
      State: "Madhya Pradesh",
      Constituency: "Tikamgarh",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae2af",
      State: "Madhya Pradesh",
      Constituency: "Damoh",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae2b0",
      State: "Madhya Pradesh",
      Constituency: "Khajuraho",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae2b1",
      State: "Madhya Pradesh",
      Constituency: "Satna",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae2b2",
      State: "Madhya Pradesh",
      Constituency: "Rewa",
      createdAt: "2024-09-03T18:53:02.793Z",
    },
    {
      _id: "66d72401c7d0285f960ae2b3",
      State: "Madhya Pradesh",
      Constituency: "Sidhi",
      createdAt: "2024-09-03T18:53:02.794Z",
    },
    {
      _id: "66d72401c7d0285f960ae2b4",
      State: "Madhya Pradesh",
      Constituency: "Shahdol",
      createdAt: "2024-09-03T18:53:02.794Z",
    },
    {
      _id: "66d72401c7d0285f960ae2b5",
      State: "Madhya Pradesh",
      Constituency: "Jabalpur",
      createdAt: "2024-09-03T18:53:02.794Z",
    },
    {
      _id: "66d72401c7d0285f960ae2b6",
      State: "Madhya Pradesh",
      Constituency: "Mandla",
      createdAt: "2024-09-03T18:53:02.794Z",
    },
    {
      _id: "66d72401c7d0285f960ae2b7",
      State: "Madhya Pradesh",
      Constituency: "Balaghat",
      createdAt: "2024-09-03T18:53:02.794Z",
    },
    {
      _id: "66d72401c7d0285f960ae2b8",
      State: "Madhya Pradesh",
      Constituency: "Chhindwara",
      createdAt: "2024-09-03T18:53:02.794Z",
    },
    {
      _id: "66d72401c7d0285f960ae2b9",
      State: "Madhya Pradesh",
      Constituency: "Hoshangabad",
      createdAt: "2024-09-03T18:53:02.794Z",
    },
    {
      _id: "66d72401c7d0285f960ae2ba",
      State: "Madhya Pradesh",
      Constituency: "Vidisha",
      createdAt: "2024-09-03T18:53:02.794Z",
    },
    {
      _id: "66d72401c7d0285f960ae2bb",
      State: "Madhya Pradesh",
      Constituency: "Bhopal",
      createdAt: "2024-09-03T18:53:02.794Z",
    },
    {
      _id: "66d72401c7d0285f960ae2bc",
      State: "Madhya Pradesh",
      Constituency: "Rajgarh",
      createdAt: "2024-09-03T18:53:02.794Z",
    },
    {
      _id: "66d72401c7d0285f960ae2bd",
      State: "Madhya Pradesh",
      Constituency: "Dewas",
      createdAt: "2024-09-03T18:53:02.794Z",
    },
    {
      _id: "66d72401c7d0285f960ae2be",
      State: "Madhya Pradesh",
      Constituency: "Ujjain",
      createdAt: "2024-09-03T18:53:02.794Z",
    },
    {
      _id: "66d72401c7d0285f960ae2bf",
      State: "Madhya Pradesh",
      Constituency: "Mandsour",
      createdAt: "2024-09-03T18:53:02.794Z",
    },
    {
      _id: "66d72401c7d0285f960ae2c0",
      State: "Madhya Pradesh",
      Constituency: "Ratlam",
      createdAt: "2024-09-03T18:53:02.794Z",
    },
    {
      _id: "66d72401c7d0285f960ae2c1",
      State: "Madhya Pradesh",
      Constituency: "Dhar",
      createdAt: "2024-09-03T18:53:02.794Z",
    },
    {
      _id: "66d72401c7d0285f960ae2c2",
      State: "Madhya Pradesh",
      Constituency: "Indore",
      createdAt: "2024-09-03T18:53:02.794Z",
    },
    {
      _id: "66d72401c7d0285f960ae2c3",
      State: "Madhya Pradesh",
      Constituency: "Khargone",
      createdAt: "2024-09-03T18:53:02.794Z",
    },
    {
      _id: "66d72401c7d0285f960ae2c4",
      State: "Madhya Pradesh",
      Constituency: "Khandwa",
      createdAt: "2024-09-03T18:53:02.794Z",
    },
    {
      _id: "66d72401c7d0285f960ae2c5",
      State: "Madhya Pradesh",
      Constituency: "Betul",
      createdAt: "2024-09-03T18:53:02.794Z",
    },
    {
      _id: "66d72401c7d0285f960ae2c6",
      State: "Maharashtra",
      Constituency: "Nandurbar",
      createdAt: "2024-09-03T18:53:02.794Z",
    },
    {
      _id: "66d72401c7d0285f960ae2c7",
      State: "Maharashtra",
      Constituency: "Dhule",
      createdAt: "2024-09-03T18:53:02.794Z",
    },
    {
      _id: "66d72401c7d0285f960ae2c8",
      State: "Maharashtra",
      Constituency: "Jalgaon",
      createdAt: "2024-09-03T18:53:02.794Z",
    },
    {
      _id: "66d72401c7d0285f960ae2c9",
      State: "Maharashtra",
      Constituency: "Raver",
      createdAt: "2024-09-03T18:53:02.794Z",
    },
    {
      _id: "66d72401c7d0285f960ae2ca",
      State: "Maharashtra",
      Constituency: "Buldhana",
      createdAt: "2024-09-03T18:53:02.794Z",
    },
    {
      _id: "66d72401c7d0285f960ae2cb",
      State: "Maharashtra",
      Constituency: "Akola",
      createdAt: "2024-09-03T18:53:02.794Z",
    },
    {
      _id: "66d72401c7d0285f960ae2cc",
      State: "Maharashtra",
      Constituency: "Amravati",
      createdAt: "2024-09-03T18:53:02.794Z",
    },
    {
      _id: "66d72401c7d0285f960ae2cd",
      State: "Maharashtra",
      Constituency: "Wardha",
      createdAt: "2024-09-03T18:53:02.794Z",
    },
    {
      _id: "66d72401c7d0285f960ae2ce",
      State: "Maharashtra",
      Constituency: "Ramtek",
      createdAt: "2024-09-03T18:53:02.795Z",
    },
    {
      _id: "66d72401c7d0285f960ae2cf",
      State: "Maharashtra",
      Constituency: "Nagpur",
      createdAt: "2024-09-03T18:53:02.795Z",
    },
    {
      _id: "66d72401c7d0285f960ae2d0",
      State: "Maharashtra",
      Constituency: "Bhandara - Gondiya",
      createdAt: "2024-09-03T18:53:02.795Z",
    },
    {
      _id: "66d72401c7d0285f960ae2d1",
      State: "Maharashtra",
      Constituency: "Gadchiroli-Chimur",
      createdAt: "2024-09-03T18:53:02.795Z",
    },
    {
      _id: "66d72401c7d0285f960ae2d2",
      State: "Maharashtra",
      Constituency: "Chandrapur",
      createdAt: "2024-09-03T18:53:02.795Z",
    },
    {
      _id: "66d72401c7d0285f960ae2d3",
      State: "Maharashtra",
      Constituency: "Yavatmal-Washim",
      createdAt: "2024-09-03T18:53:02.795Z",
    },
    {
      _id: "66d72401c7d0285f960ae2d4",
      State: "Maharashtra",
      Constituency: "Hingoli",
      createdAt: "2024-09-03T18:53:02.795Z",
    },
    {
      _id: "66d72401c7d0285f960ae2d5",
      State: "Maharashtra",
      Constituency: "Nanded",
      createdAt: "2024-09-03T18:53:02.795Z",
    },
    {
      _id: "66d72401c7d0285f960ae2d6",
      State: "Maharashtra",
      Constituency: "Parbhani",
      createdAt: "2024-09-03T18:53:02.795Z",
    },
    {
      _id: "66d72401c7d0285f960ae2d7",
      State: "Maharashtra",
      Constituency: "Jalna",
      createdAt: "2024-09-03T18:53:02.795Z",
    },
    {
      _id: "66d72401c7d0285f960ae2d8",
      State: "Maharashtra",
      Constituency: "Aurangabad",
      createdAt: "2024-09-03T18:53:02.795Z",
    },
    {
      _id: "66d72401c7d0285f960ae2d9",
      State: "Maharashtra",
      Constituency: "Dindori",
      createdAt: "2024-09-03T18:53:02.795Z",
    },
    {
      _id: "66d72401c7d0285f960ae2da",
      State: "Maharashtra",
      Constituency: "Nashik",
      createdAt: "2024-09-03T18:53:02.795Z",
    },
    {
      _id: "66d72401c7d0285f960ae2db",
      State: "Maharashtra",
      Constituency: "Palghar",
      createdAt: "2024-09-03T18:53:02.795Z",
    },
    {
      _id: "66d72401c7d0285f960ae2dc",
      State: "Maharashtra",
      Constituency: "Bhiwandi",
      createdAt: "2024-09-03T18:53:02.795Z",
    },
    {
      _id: "66d72401c7d0285f960ae2dd",
      State: "Maharashtra",
      Constituency: "Kalyan",
      createdAt: "2024-09-03T18:53:02.795Z",
    },
    {
      _id: "66d72401c7d0285f960ae2de",
      State: "Maharashtra",
      Constituency: "Thane",
      createdAt: "2024-09-03T18:53:02.795Z",
    },
    {
      _id: "66d72401c7d0285f960ae2df",
      State: "Maharashtra",
      Constituency: "Mumbai North",
      createdAt: "2024-09-03T18:53:02.795Z",
    },
    {
      _id: "66d72401c7d0285f960ae2e2",
      State: "Maharashtra",
      Constituency: "Mumbai North West",
      createdAt: "2024-09-03T18:53:02.795Z",
    },
    {
      _id: "66d72401c7d0285f960ae2e3",
      State: "Maharashtra",
      Constituency: "Mumbai North East",
      createdAt: "2024-09-03T18:53:02.795Z",
    },
    {
      _id: "66d72401c7d0285f960ae2e4",
      State: "Maharashtra",
      Constituency: "Mumbai North Central",
      createdAt: "2024-09-03T18:53:02.795Z",
    },
    {
      _id: "66d72401c7d0285f960ae2e5",
      State: "Maharashtra",
      Constituency: "Mumbai South Central",
      createdAt: "2024-09-03T18:53:02.795Z",
    },
    {
      _id: "66d72401c7d0285f960ae2e6",
      State: "Maharashtra",
      Constituency: "Mumbai South",
      createdAt: "2024-09-03T18:53:02.795Z",
    },
    {
      _id: "66d72401c7d0285f960ae2e7",
      State: "Maharashtra",
      Constituency: "Raigad",
      createdAt: "2024-09-03T18:53:02.795Z",
    },
    {
      _id: "66d72401c7d0285f960ae2e8",
      State: "Maharashtra",
      Constituency: "Maval",
      createdAt: "2024-09-03T18:53:02.795Z",
    },
    {
      _id: "66d72401c7d0285f960ae2e9",
      State: "Maharashtra",
      Constituency: "Pune",
      createdAt: "2024-09-03T18:53:02.795Z",
    },
    {
      _id: "66d72401c7d0285f960ae2ea",
      State: "Maharashtra",
      Constituency: "Baramati",
      createdAt: "2024-09-03T18:53:02.795Z",
    },
    {
      _id: "66d72401c7d0285f960ae2eb",
      State: "Maharashtra",
      Constituency: "Shirur",
      createdAt: "2024-09-03T18:53:02.795Z",
    },
    {
      _id: "66d72401c7d0285f960ae2ec",
      State: "Maharashtra",
      Constituency: "Ahmadnagar",
      createdAt: "2024-09-03T18:53:02.796Z",
    },
    {
      _id: "66d72401c7d0285f960ae2ed",
      State: "Maharashtra",
      Constituency: "Shirdi",
      createdAt: "2024-09-03T18:53:02.796Z",
    },
    {
      _id: "66d72401c7d0285f960ae2ee",
      State: "Maharashtra",
      Constituency: "Beed",
      createdAt: "2024-09-03T18:53:02.796Z",
    },
    {
      _id: "66d72401c7d0285f960ae2ef",
      State: "Maharashtra",
      Constituency: "Osmanabad",
      createdAt: "2024-09-03T18:53:02.796Z",
    },
    {
      _id: "66d72401c7d0285f960ae2f0",
      State: "Maharashtra",
      Constituency: "Latur",
      createdAt: "2024-09-03T18:53:02.796Z",
    },
    {
      _id: "66d72401c7d0285f960ae2f1",
      State: "Maharashtra",
      Constituency: "Solapur",
      createdAt: "2024-09-03T18:53:02.796Z",
    },
    {
      _id: "66d72401c7d0285f960ae2f2",
      State: "Maharashtra",
      Constituency: "Madha",
      createdAt: "2024-09-03T18:53:02.796Z",
    },
    {
      _id: "66d72401c7d0285f960ae2f3",
      State: "Maharashtra",
      Constituency: "Sangli",
      createdAt: "2024-09-03T18:53:02.796Z",
    },
    {
      _id: "66d72401c7d0285f960ae2f4",
      State: "Maharashtra",
      Constituency: "Satara",
      createdAt: "2024-09-03T18:53:02.796Z",
    },
    {
      _id: "66d72401c7d0285f960ae2f5",
      State: "Maharashtra",
      Constituency: "Ratnagiri - Sindhudurg",
      createdAt: "2024-09-03T18:53:02.796Z",
    },
    {
      _id: "66d72401c7d0285f960ae2f6",
      State: "Maharashtra",
      Constituency: "Kolhapur",
      createdAt: "2024-09-03T18:53:02.796Z",
    },
    {
      _id: "66d72401c7d0285f960ae2f7",
      State: "Maharashtra",
      Constituency: "Hatkanangle",
      createdAt: "2024-09-03T18:53:02.796Z",
    },
    {
      _id: "66d72401c7d0285f960ae2f8",
      State: "Manipur",
      Constituency: "Inner Manipur",
      createdAt: "2024-09-03T18:53:02.796Z",
    },
    {
      _id: "66d72401c7d0285f960ae2f9",
      State: "Manipur",
      Constituency: "Outer Manipur",
      createdAt: "2024-09-03T18:53:02.796Z",
    },
    {
      _id: "66d72401c7d0285f960ae2fa",
      State: "Meghalaya",
      Constituency: "Shillong",
      createdAt: "2024-09-03T18:53:02.796Z",
    },
    {
      _id: "66d72401c7d0285f960ae2fb",
      State: "Meghalaya",
      Constituency: "Tura",
      createdAt: "2024-09-03T18:53:02.796Z",
    },
    {
      _id: "66d72401c7d0285f960ae2fc",
      State: "Mizoram",
      Constituency: "Mizoram",
      createdAt: "2024-09-03T18:53:02.796Z",
    },
    {
      _id: "66d72401c7d0285f960ae2fd",
      State: "Nagaland",
      Constituency: "Nagaland",
      createdAt: "2024-09-03T18:53:02.796Z",
    },
    {
      _id: "66d72401c7d0285f960ae2fe",
      State: "NCT OF Delhi",
      Constituency: "Chandni Chowk",
      createdAt: "2024-09-03T18:53:02.796Z",
    },
    {
      _id: "66d72401c7d0285f960ae2ff",
      State: "NCT OF Delhi",
      Constituency: "North East Delhi",
      createdAt: "2024-09-03T18:53:02.796Z",
    },
    {
      _id: "66d72401c7d0285f960ae300",
      State: "NCT OF Delhi",
      Constituency: "East Delhi",
      createdAt: "2024-09-03T18:53:02.796Z",
    },
    {
      _id: "66d72401c7d0285f960ae301",
      State: "NCT OF Delhi",
      Constituency: "New Delhi",
      createdAt: "2024-09-03T18:53:02.796Z",
    },
    {
      _id: "66d72401c7d0285f960ae302",
      State: "NCT OF Delhi",
      Constituency: "North West Delhi (SC )",
      createdAt: "2024-09-03T18:53:02.796Z",
    },
    {
      _id: "66d72401c7d0285f960ae303",
      State: "NCT OF Delhi",
      Constituency: "West Delhi",
      createdAt: "2024-09-03T18:53:02.796Z",
    },
    {
      _id: "66d72401c7d0285f960ae304",
      State: "NCT OF Delhi",
      Constituency: "South Delhi",
      createdAt: "2024-09-03T18:53:02.796Z",
    },
    {
      _id: "66d72401c7d0285f960ae305",
      State: "Odisha",
      Constituency: "Bargarh",
      createdAt: "2024-09-03T18:53:02.796Z",
    },
    {
      _id: "66d72401c7d0285f960ae306",
      State: "Odisha",
      Constituency: "Sundargarh",
      createdAt: "2024-09-03T18:53:02.796Z",
    },
    {
      _id: "66d72401c7d0285f960ae307",
      State: "Odisha",
      Constituency: "Sambalpur",
      createdAt: "2024-09-03T18:53:02.797Z",
    },
    {
      _id: "66d72401c7d0285f960ae308",
      State: "Odisha",
      Constituency: "Keonjhar",
      createdAt: "2024-09-03T18:53:02.797Z",
    },
    {
      _id: "66d72401c7d0285f960ae309",
      State: "Odisha",
      Constituency: "Mayurbhanj",
      createdAt: "2024-09-03T18:53:02.797Z",
    },
    {
      _id: "66d72401c7d0285f960ae30a",
      State: "Odisha",
      Constituency: "Balasore",
      createdAt: "2024-09-03T18:53:02.797Z",
    },
    {
      _id: "66d72401c7d0285f960ae30b",
      State: "Odisha",
      Constituency: "Bhadrak",
      createdAt: "2024-09-03T18:53:02.797Z",
    },
    {
      _id: "66d72401c7d0285f960ae30c",
      State: "Odisha",
      Constituency: "Jajpur",
      createdAt: "2024-09-03T18:53:02.797Z",
    },
    {
      _id: "66d72401c7d0285f960ae30d",
      State: "Odisha",
      Constituency: "Dhenkanal",
      createdAt: "2024-09-03T18:53:02.797Z",
    },
    {
      _id: "66d72401c7d0285f960ae30e",
      State: "Odisha",
      Constituency: "Bolangir",
      createdAt: "2024-09-03T18:53:02.797Z",
    },
    {
      _id: "66d72401c7d0285f960ae30f",
      State: "Odisha",
      Constituency: "Kalahandi",
      createdAt: "2024-09-03T18:53:02.797Z",
    },
    {
      _id: "66d72401c7d0285f960ae310",
      State: "Odisha",
      Constituency: "Nabarangpur",
      createdAt: "2024-09-03T18:53:02.797Z",
    },
    {
      _id: "66d72401c7d0285f960ae311",
      State: "Odisha",
      Constituency: "Kandhamal",
      createdAt: "2024-09-03T18:53:02.797Z",
    },
    {
      _id: "66d72401c7d0285f960ae312",
      State: "Odisha",
      Constituency: "Cuttack",
      createdAt: "2024-09-03T18:53:02.797Z",
    },
    {
      _id: "66d72401c7d0285f960ae313",
      State: "Odisha",
      Constituency: "Kendrapara",
      createdAt: "2024-09-03T18:53:02.797Z",
    },
    {
      _id: "66d72401c7d0285f960ae314",
      State: "Odisha",
      Constituency: "Jagatsinghpur",
      createdAt: "2024-09-03T18:53:02.797Z",
    },
    {
      _id: "66d72401c7d0285f960ae315",
      State: "Odisha",
      Constituency: "Puri",
      createdAt: "2024-09-03T18:53:02.797Z",
    },
    {
      _id: "66d72401c7d0285f960ae316",
      State: "Odisha",
      Constituency: "Bhubaneswar",
      createdAt: "2024-09-03T18:53:02.797Z",
    },
    {
      _id: "66d72401c7d0285f960ae317",
      State: "Odisha",
      Constituency: "Aska",
      createdAt: "2024-09-03T18:53:02.797Z",
    },
    {
      _id: "66d72401c7d0285f960ae318",
      State: "Odisha",
      Constituency: "Berhampur",
      createdAt: "2024-09-03T18:53:02.797Z",
    },
    {
      _id: "66d72401c7d0285f960ae319",
      State: "Odisha",
      Constituency: "Koraput",
      createdAt: "2024-09-03T18:53:02.797Z",
    },
    {
      _id: "66d72401c7d0285f960ae31a",
      State: "Puducherry",
      Constituency: "Puducherry",
      createdAt: "2024-09-03T18:53:02.797Z",
    },
    {
      _id: "66d72401c7d0285f960ae31b",
      State: "Punjab",
      Constituency: "Gurdaspur",
      createdAt: "2024-09-03T18:53:02.797Z",
    },
    {
      _id: "66d72401c7d0285f960ae31c",
      State: "Punjab",
      Constituency: "Amritsar",
      createdAt: "2024-09-03T18:53:02.797Z",
    },
    {
      _id: "66d72401c7d0285f960ae31d",
      State: "Punjab",
      Constituency: "Khadoor Sahib",
      createdAt: "2024-09-03T18:53:02.799Z",
    },
    {
      _id: "66d72401c7d0285f960ae31e",
      State: "Punjab",
      Constituency: "Jalandhar",
      createdAt: "2024-09-03T18:53:02.799Z",
    },
    {
      _id: "66d72401c7d0285f960ae31f",
      State: "Punjab",
      Constituency: "Hoshiarpur",
      createdAt: "2024-09-03T18:53:02.799Z",
    },
    {
      _id: "66d72401c7d0285f960ae320",
      State: "Punjab",
      Constituency: "Anandpur Sahib",
      createdAt: "2024-09-03T18:53:02.799Z",
    },
    {
      _id: "66d72401c7d0285f960ae321",
      State: "Punjab",
      Constituency: "Ludhiana",
      createdAt: "2024-09-03T18:53:02.799Z",
    },
    {
      _id: "66d72401c7d0285f960ae323",
      State: "Punjab",
      Constituency: "Fatehgarh Sahib",
      createdAt: "2024-09-03T18:53:02.799Z",
    },
    {
      _id: "66d72401c7d0285f960ae324",
      State: "Punjab",
      Constituency: "Faridkot",
      createdAt: "2024-09-03T18:53:02.799Z",
    },
    {
      _id: "66d72401c7d0285f960ae325",
      State: "Punjab",
      Constituency: "Firozpur",
      createdAt: "2024-09-03T18:53:02.799Z",
    },
    {
      _id: "66d72401c7d0285f960ae326",
      State: "Punjab",
      Constituency: "Bathinda",
      createdAt: "2024-09-03T18:53:02.799Z",
    },
    {
      _id: "66d72401c7d0285f960ae327",
      State: "Punjab",
      Constituency: "Sangrur",
      createdAt: "2024-09-03T18:53:02.799Z",
    },
    {
      _id: "66d72401c7d0285f960ae328",
      State: "Punjab",
      Constituency: "Patiala",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae329",
      State: "Rajasthan",
      Constituency: "Ganganagar",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae32a",
      State: "Rajasthan",
      Constituency: "Bikaner",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae32b",
      State: "Rajasthan",
      Constituency: "Churu",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae32c",
      State: "Rajasthan",
      Constituency: "Jhunjhunu",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae32d",
      State: "Rajasthan",
      Constituency: "Sikar",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae32e",
      State: "Rajasthan",
      Constituency: "Jaipur Rural",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae32f",
      State: "Rajasthan",
      Constituency: "Jaipur",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae330",
      State: "Rajasthan",
      Constituency: "Alwar",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae331",
      State: "Rajasthan",
      Constituency: "Bharatpur",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae332",
      State: "Rajasthan",
      Constituency: "Karauli-Dholpur",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae333",
      State: "Rajasthan",
      Constituency: "Dausa",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae334",
      State: "Rajasthan",
      Constituency: "Tonk-Sawai Madhopur",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae335",
      State: "Rajasthan",
      Constituency: "Ajmer",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae336",
      State: "Rajasthan",
      Constituency: "Nagaur",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae337",
      State: "Rajasthan",
      Constituency: "Pali",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae338",
      State: "Rajasthan",
      Constituency: "Jodhpur",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae339",
      State: "Rajasthan",
      Constituency: "Barmer",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae33a",
      State: "Rajasthan",
      Constituency: "Jalore",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae33b",
      State: "Rajasthan",
      Constituency: "Udaipur",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae33c",
      State: "Rajasthan",
      Constituency: "Banswara",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae33d",
      State: "Rajasthan",
      Constituency: "Chittorgarh",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae33e",
      State: "Rajasthan",
      Constituency: "Rajsamand",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae33f",
      State: "Rajasthan",
      Constituency: "Bhilwara",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae340",
      State: "Rajasthan",
      Constituency: "Kota",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae341",
      State: "Rajasthan",
      Constituency: "Jhalawar-Baran",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae342",
      State: "Sikkim",
      Constituency: "Sikkim",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae343",
      State: "Tamil Nadu",
      Constituency: "Thiruvallur",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae344",
      State: "Tamil Nadu",
      Constituency: "Chennai North",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae345",
      State: "Tamil Nadu",
      Constituency: "Chennai South",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae346",
      State: "Tamil Nadu",
      Constituency: "Chennai Central",
      createdAt: "2024-09-03T18:53:02.800Z",
    },
    {
      _id: "66d72401c7d0285f960ae347",
      State: "Tamil Nadu",
      Constituency: "Sriperumbudur",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae348",
      State: "Tamil Nadu",
      Constituency: "Kancheepuram",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae349",
      State: "Tamil Nadu",
      Constituency: "Arakkonam",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae34a",
      State: "Tamil Nadu",
      Constituency: "Vellore",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae34b",
      State: "Tamil Nadu",
      Constituency: "Krishnagiri",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae34c",
      State: "Tamil Nadu",
      Constituency: "Dharmapuri",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae34d",
      State: "Tamil Nadu",
      Constituency: "Tiruvannamalai",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae34e",
      State: "Tamil Nadu",
      Constituency: "Arani",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae34f",
      State: "Tamil Nadu",
      Constituency: "Viluppuram",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae350",
      State: "Tamil Nadu",
      Constituency: "Kallakurichi",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae351",
      State: "Tamil Nadu",
      Constituency: "Salem",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae352",
      State: "Tamil Nadu",
      Constituency: "Namakkal",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae353",
      State: "Tamil Nadu",
      Constituency: "Erode",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae354",
      State: "Tamil Nadu",
      Constituency: "Tiruppur",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae356",
      State: "Tamil Nadu",
      Constituency: "Nilgiris",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae357",
      State: "Tamil Nadu",
      Constituency: "Coimbatore",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae358",
      State: "Tamil Nadu",
      Constituency: "Pollachi",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae359",
      State: "Tamil Nadu",
      Constituency: "Dindigul",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae35a",
      State: "Tamil Nadu",
      Constituency: "Karur",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae35b",
      State: "Tamil Nadu",
      Constituency: "Tiruchirappalli",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae35c",
      State: "Tamil Nadu",
      Constituency: "Perambalur",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae35d",
      State: "Tamil Nadu",
      Constituency: "Cuddalore",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae35e",
      State: "Tamil Nadu",
      Constituency: "Chidambaram",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae35f",
      State: "Tamil Nadu",
      Constituency: "Mayiladuthurai",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae360",
      State: "Tamil Nadu",
      Constituency: "Nagapattinam",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae361",
      State: "Tamil Nadu",
      Constituency: "Thanjavur",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae362",
      State: "Tamil Nadu",
      Constituency: "Sivaganga",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae363",
      State: "Tamil Nadu",
      Constituency: "Madurai",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae365",
      State: "Tamil Nadu",
      Constituency: "Theni",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae366",
      State: "Tamil Nadu",
      Constituency: "Virudhunagar",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae367",
      State: "Tamil Nadu",
      Constituency: "Ramanathapuram",
      createdAt: "2024-09-03T18:53:02.801Z",
    },
    {
      _id: "66d72401c7d0285f960ae368",
      State: "Tamil Nadu",
      Constituency: "Thoothukkudi",
      createdAt: "2024-09-03T18:53:02.802Z",
    },
    {
      _id: "66d72401c7d0285f960ae369",
      State: "Tamil Nadu",
      Constituency: "Tenkasi",
      createdAt: "2024-09-03T18:53:02.802Z",
    },
    {
      _id: "66d72401c7d0285f960ae36a",
      State: "Tamil Nadu",
      Constituency: "Tirunelveli",
      createdAt: "2024-09-03T18:53:02.802Z",
    },
    {
      _id: "66d72401c7d0285f960ae36b",
      State: "Tamil Nadu",
      Constituency: "Kanniyakumari",
      createdAt: "2024-09-03T18:53:02.802Z",
    },
    {
      _id: "66d72401c7d0285f960ae36c",
      State: "Telangana",
      Constituency: "Adilabad",
      createdAt: "2024-09-03T18:53:02.802Z",
    },
    {
      _id: "66d72401c7d0285f960ae36d",
      State: "Telangana",
      Constituency: "Peddapalle",
      createdAt: "2024-09-03T18:53:02.802Z",
    },
    {
      _id: "66d72401c7d0285f960ae36e",
      State: "Telangana",
      Constituency: "Karimnagar",
      createdAt: "2024-09-03T18:53:02.802Z",
    },
    {
      _id: "66d72401c7d0285f960ae36f",
      State: "Telangana",
      Constituency: "Nizamabad",
      createdAt: "2024-09-03T18:53:02.802Z",
    },
    {
      _id: "66d72401c7d0285f960ae370",
      State: "Telangana",
      Constituency: "Zahirabad",
      createdAt: "2024-09-03T18:53:02.802Z",
    },
    {
      _id: "66d72401c7d0285f960ae371",
      State: "Telangana",
      Constituency: "Medak",
      createdAt: "2024-09-03T18:53:02.802Z",
    },
    {
      _id: "66d72401c7d0285f960ae372",
      State: "Telangana",
      Constituency: "Malkajgiri",
      createdAt: "2024-09-03T18:53:02.802Z",
    },
    {
      _id: "66d72401c7d0285f960ae373",
      State: "Telangana",
      Constituency: "Secundrabad",
      createdAt: "2024-09-03T18:53:02.802Z",
    },
    {
      _id: "66d72401c7d0285f960ae374",
      State: "Telangana",
      Constituency: "Hyderabad",
      createdAt: "2024-09-03T18:53:02.802Z",
    },
    {
      _id: "66d72401c7d0285f960ae375",
      State: "Telangana",
      Constituency: "Chelvella",
      createdAt: "2024-09-03T18:53:02.802Z",
    },
    {
      _id: "66d72401c7d0285f960ae376",
      State: "Telangana",
      Constituency: "Mahbubnagar",
      createdAt: "2024-09-03T18:53:02.802Z",
    },
    {
      _id: "66d72401c7d0285f960ae377",
      State: "Telangana",
      Constituency: "Nagarkurnool",
      createdAt: "2024-09-03T18:53:02.802Z",
    },
    {
      _id: "66d72401c7d0285f960ae378",
      State: "Telangana",
      Constituency: "Nalgonda",
      createdAt: "2024-09-03T18:53:02.802Z",
    },
    {
      _id: "66d72401c7d0285f960ae379",
      State: "Telangana",
      Constituency: "Bhongir",
      createdAt: "2024-09-03T18:53:02.802Z",
    },
    {
      _id: "66d72401c7d0285f960ae37a",
      State: "Telangana",
      Constituency: "Warangal",
      createdAt: "2024-09-03T18:53:02.802Z",
    },
    {
      _id: "66d72401c7d0285f960ae37b",
      State: "Telangana",
      Constituency: "Mahabubabad",
      createdAt: "2024-09-03T18:53:02.802Z",
    },
    {
      _id: "66d72401c7d0285f960ae37c",
      State: "Telangana",
      Constituency: "Khammam",
      createdAt: "2024-09-03T18:53:02.802Z",
    },
    {
      _id: "66d72401c7d0285f960ae37d",
      State: "Tripura",
      Constituency: "Tripura West",
      createdAt: "2024-09-03T18:53:02.802Z",
    },
    {
      _id: "66d72401c7d0285f960ae37e",
      State: "Tripura",
      Constituency: "Tripura East",
      createdAt: "2024-09-03T18:53:02.802Z",
    },
    {
      _id: "66d72401c7d0285f960ae37f",
      State: "Uttar Pradesh",
      Constituency: "Saharanpur",
      createdAt: "2024-09-03T18:53:02.803Z",
    },
    {
      _id: "66d72401c7d0285f960ae380",
      State: "Uttar Pradesh",
      Constituency: "Kairana",
      createdAt: "2024-09-03T18:53:02.803Z",
    },
    {
      _id: "66d72401c7d0285f960ae381",
      State: "Uttar Pradesh",
      Constituency: "Muzaffarnagar",
      createdAt: "2024-09-03T18:53:02.803Z",
    },
    {
      _id: "66d72401c7d0285f960ae382",
      State: "Uttar Pradesh",
      Constituency: "Bijnor",
      createdAt: "2024-09-03T18:53:02.803Z",
    },
    {
      _id: "66d72401c7d0285f960ae383",
      State: "Uttar Pradesh",
      Constituency: "Nagina",
      createdAt: "2024-09-03T18:53:02.803Z",
    },
    {
      _id: "66d72401c7d0285f960ae384",
      State: "Uttar Pradesh",
      Constituency: "Moradabad",
      createdAt: "2024-09-03T18:53:02.803Z",
    },
    {
      _id: "66d72401c7d0285f960ae385",
      State: "Uttar Pradesh",
      Constituency: "Rampur",
      createdAt: "2024-09-03T18:53:02.803Z",
    },
    {
      _id: "66d72401c7d0285f960ae386",
      State: "Uttar Pradesh",
      Constituency: "Sambhal",
      createdAt: "2024-09-03T18:53:02.803Z",
    },
    {
      _id: "66d72401c7d0285f960ae387",
      State: "Uttar Pradesh",
      Constituency: "Amroha",
      createdAt: "2024-09-03T18:53:02.803Z",
    },
    {
      _id: "66d72401c7d0285f960ae388",
      State: "Uttar Pradesh",
      Constituency: "Meerut",
      createdAt: "2024-09-03T18:53:02.803Z",
    },
    {
      _id: "66d72401c7d0285f960ae389",
      State: "Uttar Pradesh",
      Constituency: "Baghpat",
      createdAt: "2024-09-03T18:53:02.803Z",
    },
    {
      _id: "66d72401c7d0285f960ae38a",
      State: "Uttar Pradesh",
      Constituency: "Ghaziabad",
      createdAt: "2024-09-03T18:53:02.803Z",
    },
    {
      _id: "66d72401c7d0285f960ae38b",
      State: "Uttar Pradesh",
      Constituency: "Gautam Buddha Nagar",
      createdAt: "2024-09-03T18:53:02.803Z",
    },
    {
      _id: "66d72401c7d0285f960ae38c",
      State: "Uttar Pradesh",
      Constituency: "Bulandshahr",
      createdAt: "2024-09-03T18:53:02.803Z",
    },
    {
      _id: "66d72401c7d0285f960ae38d",
      State: "Uttar Pradesh",
      Constituency: "Aligarh",
      createdAt: "2024-09-03T18:53:02.803Z",
    },
    {
      _id: "66d72401c7d0285f960ae38e",
      State: "Uttar Pradesh",
      Constituency: "Hathras",
      createdAt: "2024-09-03T18:53:02.803Z",
    },
    {
      _id: "66d72401c7d0285f960ae38f",
      State: "Uttar Pradesh",
      Constituency: "Mathura",
      createdAt: "2024-09-03T18:53:02.803Z",
    },
    {
      _id: "66d72401c7d0285f960ae390",
      State: "Uttar Pradesh",
      Constituency: "Agra",
      createdAt: "2024-09-03T18:53:02.803Z",
    },
    {
      _id: "66d72401c7d0285f960ae391",
      State: "Uttar Pradesh",
      Constituency: "Fatehpur Sikri",
      createdAt: "2024-09-03T18:53:02.803Z",
    },
    {
      _id: "66d72401c7d0285f960ae392",
      State: "Uttar Pradesh",
      Constituency: "Firozabad",
      createdAt: "2024-09-03T18:53:02.803Z",
    },
    {
      _id: "66d72401c7d0285f960ae393",
      State: "Uttar Pradesh",
      Constituency: "Mainpuri",
      createdAt: "2024-09-03T18:53:02.803Z",
    },
    {
      _id: "66d72401c7d0285f960ae394",
      State: "Uttar Pradesh",
      Constituency: "Etah",
      createdAt: "2024-09-03T18:53:02.803Z",
    },
    {
      _id: "66d72401c7d0285f960ae395",
      State: "Uttar Pradesh",
      Constituency: "Badaun",
      createdAt: "2024-09-03T18:53:02.803Z",
    },
    {
      _id: "66d72401c7d0285f960ae396",
      State: "Uttar Pradesh",
      Constituency: "Aonla",
      createdAt: "2024-09-03T18:53:02.803Z",
    },
    {
      _id: "66d72401c7d0285f960ae397",
      State: "Uttar Pradesh",
      Constituency: "Bareilly",
      createdAt: "2024-09-03T18:53:02.804Z",
    },
    {
      _id: "66d72401c7d0285f960ae398",
      State: "Uttar Pradesh",
      Constituency: "Pilibhit",
      createdAt: "2024-09-03T18:53:02.804Z",
    },
    {
      _id: "66d72401c7d0285f960ae399",
      State: "Uttar Pradesh",
      Constituency: "Shahjahanpur",
      createdAt: "2024-09-03T18:53:02.804Z",
    },
    {
      _id: "66d72401c7d0285f960ae39a",
      State: "Uttar Pradesh",
      Constituency: "Kheri",
      createdAt: "2024-09-03T18:53:02.804Z",
    },
    {
      _id: "66d72401c7d0285f960ae39b",
      State: "Uttar Pradesh",
      Constituency: "Dhaurahra",
      createdAt: "2024-09-03T18:53:02.804Z",
    },
    {
      _id: "66d72401c7d0285f960ae39c",
      State: "Uttar Pradesh",
      Constituency: "Sitapur",
      createdAt: "2024-09-03T18:53:02.804Z",
    },
    {
      _id: "66d72401c7d0285f960ae39d",
      State: "Uttar Pradesh",
      Constituency: "Hardoi",
      createdAt: "2024-09-03T18:53:02.804Z",
    },
    {
      _id: "66d72401c7d0285f960ae39e",
      State: "Uttar Pradesh",
      Constituency: "Misrikh",
      createdAt: "2024-09-03T18:53:02.804Z",
    },
    {
      _id: "66d72401c7d0285f960ae39f",
      State: "Uttar Pradesh",
      Constituency: "Unnao",
      createdAt: "2024-09-03T18:53:02.804Z",
    },
    {
      _id: "66d72401c7d0285f960ae3a0",
      State: "Uttar Pradesh",
      Constituency: "Mohanlalganj",
      createdAt: "2024-09-03T18:53:02.804Z",
    },
    {
      _id: "66d72401c7d0285f960ae3a1",
      State: "Uttar Pradesh",
      Constituency: "Lucknow",
      createdAt: "2024-09-03T18:53:02.804Z",
    },
    {
      _id: "66d72401c7d0285f960ae3a2",
      State: "Uttar Pradesh",
      Constituency: "Rae Bareli",
      createdAt: "2024-09-03T18:53:02.804Z",
    },
    {
      _id: "66d72401c7d0285f960ae3a3",
      State: "Uttar Pradesh",
      Constituency: "Amethi",
      createdAt: "2024-09-03T18:53:02.804Z",
    },
    {
      _id: "66d72401c7d0285f960ae3a4",
      State: "Uttar Pradesh",
      Constituency: "Sultanpur",
      createdAt: "2024-09-03T18:53:02.804Z",
    },
    {
      _id: "66d72401c7d0285f960ae3a7",
      State: "Uttar Pradesh",
      Constituency: "Pratapgarh",
      createdAt: "2024-09-03T18:53:02.804Z",
    },
    {
      _id: "66d72401c7d0285f960ae3a8",
      State: "Uttar Pradesh",
      Constituency: "Farrukhabad",
      createdAt: "2024-09-03T18:53:02.804Z",
    },
    {
      _id: "66d72401c7d0285f960ae3a9",
      State: "Uttar Pradesh",
      Constituency: "Etawah",
      createdAt: "2024-09-03T18:53:02.804Z",
    },
    {
      _id: "66d72401c7d0285f960ae3aa",
      State: "Uttar Pradesh",
      Constituency: "Kannauj",
      createdAt: "2024-09-03T18:53:02.804Z",
    },
    {
      _id: "66d72401c7d0285f960ae3ab",
      State: "Uttar Pradesh",
      Constituency: "Kanpur",
      createdAt: "2024-09-03T18:53:02.804Z",
    },
    {
      _id: "66d72401c7d0285f960ae3ac",
      State: "Uttar Pradesh",
      Constituency: "Akbarpur",
      createdAt: "2024-09-03T18:53:02.805Z",
    },
    {
      _id: "66d72401c7d0285f960ae3ad",
      State: "Uttar Pradesh",
      Constituency: "Jalaun",
      createdAt: "2024-09-03T18:53:02.805Z",
    },
    {
      _id: "66d72401c7d0285f960ae3ae",
      State: "Uttar Pradesh",
      Constituency: "Jhansi",
      createdAt: "2024-09-03T18:53:02.805Z",
    },
    {
      _id: "66d72401c7d0285f960ae3af",
      State: "Uttar Pradesh",
      Constituency: "Hamirpur",
      createdAt: "2024-09-03T18:53:02.805Z",
    },
    {
      _id: "66d72401c7d0285f960ae3b0",
      State: "Uttar Pradesh",
      Constituency: "Banda",
      createdAt: "2024-09-03T18:53:02.805Z",
    },
    {
      _id: "66d72401c7d0285f960ae3b1",
      State: "Uttar Pradesh",
      Constituency: "Fatehpur",
      createdAt: "2024-09-03T18:53:02.805Z",
    },
    {
      _id: "66d72401c7d0285f960ae3b2",
      State: "Uttar Pradesh",
      Constituency: "Kaushambi",
      createdAt: "2024-09-03T18:53:02.805Z",
    },
    {
      _id: "66d72401c7d0285f960ae3b3",
      State: "Uttar Pradesh",
      Constituency: "Phulpur",
      createdAt: "2024-09-03T18:53:02.805Z",
    },
    {
      _id: "66d72401c7d0285f960ae3b4",
      State: "Uttar Pradesh",
      Constituency: "Allahabad",
      createdAt: "2024-09-03T18:53:02.805Z",
    },
    {
      _id: "66d72401c7d0285f960ae3b5",
      State: "Uttar Pradesh",
      Constituency: "Barabanki",
      createdAt: "2024-09-03T18:53:02.805Z",
    },
    {
      _id: "66d72401c7d0285f960ae3b6",
      State: "Uttar Pradesh",
      Constituency: "Faizabad",
      createdAt: "2024-09-03T18:53:02.805Z",
    },
    {
      _id: "66d72401c7d0285f960ae3b7",
      State: "Uttar Pradesh",
      Constituency: "Ambedkar Nagar",
      createdAt: "2024-09-03T18:53:02.805Z",
    },
    {
      _id: "66d72401c7d0285f960ae3b8",
      State: "Uttar Pradesh",
      Constituency: "Bahraich",
      createdAt: "2024-09-03T18:53:02.805Z",
    },
    {
      _id: "66d72401c7d0285f960ae3b9",
      State: "Uttar Pradesh",
      Constituency: "Kaiserganj",
      createdAt: "2024-09-03T18:53:02.805Z",
    },
    {
      _id: "66d72401c7d0285f960ae3ba",
      State: "Uttar Pradesh",
      Constituency: "Shrawasti",
      createdAt: "2024-09-03T18:53:02.805Z",
    },
    {
      _id: "66d72401c7d0285f960ae3bb",
      State: "Uttar Pradesh",
      Constituency: "Gonda",
      createdAt: "2024-09-03T18:53:02.805Z",
    },
    {
      _id: "66d72401c7d0285f960ae3bc",
      State: "Uttar Pradesh",
      Constituency: "Domariyaganj",
      createdAt: "2024-09-03T18:53:02.805Z",
    },
    {
      _id: "66d72401c7d0285f960ae3bd",
      State: "Uttar Pradesh",
      Constituency: "Basti",
      createdAt: "2024-09-03T18:53:02.805Z",
    },
    {
      _id: "66d72401c7d0285f960ae3be",
      State: "Uttar Pradesh",
      Constituency: "Sant Kabir Nagar",
      createdAt: "2024-09-03T18:53:02.806Z",
    },
    {
      _id: "66d72401c7d0285f960ae3bf",
      State: "Uttar Pradesh",
      Constituency: "Maharajganj",
      createdAt: "2024-09-03T18:53:02.806Z",
    },
    {
      _id: "66d72401c7d0285f960ae3c0",
      State: "Uttar Pradesh",
      Constituency: "Gorakhpur",
      createdAt: "2024-09-03T18:53:02.806Z",
    },
    {
      _id: "66d72401c7d0285f960ae3c1",
      State: "Uttar Pradesh",
      Constituency: "Kushi Nagar",
      createdAt: "2024-09-03T18:53:02.806Z",
    },
    {
      _id: "66d72401c7d0285f960ae3c2",
      State: "Uttar Pradesh",
      Constituency: "Deoria",
      createdAt: "2024-09-03T18:53:02.806Z",
    },
    {
      _id: "66d72401c7d0285f960ae3c3",
      State: "Uttar Pradesh",
      Constituency: "Bansgaon",
      createdAt: "2024-09-03T18:53:02.806Z",
    },
    {
      _id: "66d72401c7d0285f960ae3c4",
      State: "Uttar Pradesh",
      Constituency: "Lalganj",
      createdAt: "2024-09-03T18:53:02.806Z",
    },
    {
      _id: "66d72401c7d0285f960ae3c5",
      State: "Uttar Pradesh",
      Constituency: "Azamgarh",
      createdAt: "2024-09-03T18:53:02.806Z",
    },
    {
      _id: "66d72401c7d0285f960ae3c6",
      State: "Uttar Pradesh",
      Constituency: "Ghosi",
      createdAt: "2024-09-03T18:53:02.806Z",
    },
    {
      _id: "66d72401c7d0285f960ae3c7",
      State: "Uttar Pradesh",
      Constituency: "Salempur",
      createdAt: "2024-09-03T18:53:02.806Z",
    },
    {
      _id: "66d72401c7d0285f960ae3c8",
      State: "Uttar Pradesh",
      Constituency: "Ballia",
      createdAt: "2024-09-03T18:53:02.806Z",
    },
    {
      _id: "66d72401c7d0285f960ae3c9",
      State: "Uttar Pradesh",
      Constituency: "Jaunpur",
      createdAt: "2024-09-03T18:53:02.806Z",
    },
    {
      _id: "66d72401c7d0285f960ae3ca",
      State: "Uttar Pradesh",
      Constituency: "Machhlishahr",
      createdAt: "2024-09-03T18:53:02.806Z",
    },
    {
      _id: "66d72401c7d0285f960ae3cb",
      State: "Uttar Pradesh",
      Constituency: "Ghazipur",
      createdAt: "2024-09-03T18:53:02.806Z",
    },
    {
      _id: "66d72401c7d0285f960ae3cc",
      State: "Uttar Pradesh",
      Constituency: "Chandauli",
      createdAt: "2024-09-03T18:53:02.806Z",
    },
    {
      _id: "66d72401c7d0285f960ae3cd",
      State: "Uttar Pradesh",
      Constituency: "Varanasi",
      createdAt: "2024-09-03T18:53:02.806Z",
    },
    {
      _id: "66d72401c7d0285f960ae3ce",
      State: "Uttar Pradesh",
      Constituency: "Bhadohi",
      createdAt: "2024-09-03T18:53:02.806Z",
    },
    {
      _id: "66d72401c7d0285f960ae3cf",
      State: "Uttar Pradesh",
      Constituency: "Mirzapur",
      createdAt: "2024-09-03T18:53:02.806Z",
    },
    {
      _id: "66d72401c7d0285f960ae3d0",
      State: "Uttar Pradesh",
      Constituency: "Robertsganj",
      createdAt: "2024-09-03T18:53:02.806Z",
    },
    {
      _id: "66d72401c7d0285f960ae3d1",
      State: "Uttarakhand",
      Constituency: "Tehri Garhwal",
      createdAt: "2024-09-03T18:53:02.807Z",
    },
    {
      _id: "66d72401c7d0285f960ae3d2",
      State: "Uttarakhand",
      Constituency: "Garhwal",
      createdAt: "2024-09-03T18:53:02.807Z",
    },
    {
      _id: "66d72401c7d0285f960ae3d3",
      State: "Uttarakhand",
      Constituency: "Almora",
      createdAt: "2024-09-03T18:53:02.807Z",
    },
    {
      _id: "66d72401c7d0285f960ae3d4",
      State: "Uttarakhand",
      Constituency: "Nainital-Udhamsingh Nagar",
      createdAt: "2024-09-03T18:53:02.807Z",
    },
    {
      _id: "66d72401c7d0285f960ae3d5",
      State: "Uttarakhand",
      Constituency: "Hardwar",
      createdAt: "2024-09-03T18:53:02.807Z",
    },
    {
      _id: "66d72401c7d0285f960ae3d6",
      State: "West Bengal",
      Constituency: "Cooch Behar",
      createdAt: "2024-09-03T18:53:02.807Z",
    },
    {
      _id: "66d72401c7d0285f960ae3d7",
      State: "West Bengal",
      Constituency: "Alipurduars",
      createdAt: "2024-09-03T18:53:02.807Z",
    },
    {
      _id: "66d72401c7d0285f960ae3d8",
      State: "West Bengal",
      Constituency: "Jalpaiguri",
      createdAt: "2024-09-03T18:53:02.807Z",
    },
    {
      _id: "66d72401c7d0285f960ae3d9",
      State: "West Bengal",
      Constituency: "Darjeeling",
      createdAt: "2024-09-03T18:53:02.807Z",
    },
    {
      _id: "66d72401c7d0285f960ae3da",
      State: "West Bengal",
      Constituency: "Raiganj",
      createdAt: "2024-09-03T18:53:02.807Z",
    },
    {
      _id: "66d72401c7d0285f960ae3db",
      State: "West Bengal",
      Constituency: "Balurghat",
      createdAt: "2024-09-03T18:53:02.807Z",
    },
    {
      _id: "66d72401c7d0285f960ae3dc",
      State: "West Bengal",
      Constituency: "Maldaha Uttar",
      createdAt: "2024-09-03T18:53:02.807Z",
    },
    {
      _id: "66d72401c7d0285f960ae3dd",
      State: "West Bengal",
      Constituency: "Maldaha Dakshin",
      createdAt: "2024-09-03T18:53:02.807Z",
    },
    {
      _id: "66d72401c7d0285f960ae3de",
      State: "West Bengal",
      Constituency: "Jangipur",
      createdAt: "2024-09-03T18:53:02.807Z",
    },
    {
      _id: "66d72401c7d0285f960ae3df",
      State: "West Bengal",
      Constituency: "Baharampur",
      createdAt: "2024-09-03T18:53:02.807Z",
    },
    {
      _id: "66d72401c7d0285f960ae3e0",
      State: "West Bengal",
      Constituency: "Murshidabad",
      createdAt: "2024-09-03T18:53:02.807Z",
    },
    {
      _id: "66d72401c7d0285f960ae3e1",
      State: "West Bengal",
      Constituency: "Krishnanagar",
      createdAt: "2024-09-03T18:53:02.807Z",
    },
    {
      _id: "66d72401c7d0285f960ae3e2",
      State: "West Bengal",
      Constituency: "Ranaghat",
      createdAt: "2024-09-03T18:53:02.807Z",
    },
    {
      _id: "66d72401c7d0285f960ae3e3",
      State: "West Bengal",
      Constituency: "Bangaon",
      createdAt: "2024-09-03T18:53:02.807Z",
    },
    {
      _id: "66d72401c7d0285f960ae3e4",
      State: "West Bengal",
      Constituency: "Barrackpore",
      createdAt: "2024-09-03T18:53:02.807Z",
    },
    {
      _id: "66d72401c7d0285f960ae3e5",
      State: "West Bengal",
      Constituency: "Dum Dum",
      createdAt: "2024-09-03T18:53:02.807Z",
    },
    {
      _id: "66d72401c7d0285f960ae3e6",
      State: "West Bengal",
      Constituency: "Barasat",
      createdAt: "2024-09-03T18:53:02.807Z",
    },
    {
      _id: "66d72401c7d0285f960ae3e9",
      State: "West Bengal",
      Constituency: "Basirhat",
      createdAt: "2024-09-03T18:53:02.807Z",
    },
    {
      _id: "66d72401c7d0285f960ae3ea",
      State: "West Bengal",
      Constituency: "Joynagar",
      createdAt: "2024-09-03T18:53:02.808Z",
    },
    {
      _id: "66d72401c7d0285f960ae3eb",
      State: "West Bengal",
      Constituency: "Mathurapur",
      createdAt: "2024-09-03T18:53:02.808Z",
    },
    {
      _id: "66d72401c7d0285f960ae3ec",
      State: "West Bengal",
      Constituency: "Diamond Harbour",
      createdAt: "2024-09-03T18:53:02.808Z",
    },
    {
      _id: "66d72401c7d0285f960ae3ed",
      State: "West Bengal",
      Constituency: "Jadavpur",
      createdAt: "2024-09-03T18:53:02.808Z",
    },
    {
      _id: "66d72401c7d0285f960ae3ee",
      State: "West Bengal",
      Constituency: "Kolkata Dakshin",
      createdAt: "2024-09-03T18:53:02.808Z",
    },
    {
      _id: "66d72401c7d0285f960ae3ef",
      State: "West Bengal",
      Constituency: "Kolkata Uttar",
      createdAt: "2024-09-03T18:53:02.808Z",
    },
    {
      _id: "66d72401c7d0285f960ae3f0",
      State: "West Bengal",
      Constituency: "Howrah",
      createdAt: "2024-09-03T18:53:02.808Z",
    },
    {
      _id: "66d72401c7d0285f960ae3f1",
      State: "West Bengal",
      Constituency: "Uluberia",
      createdAt: "2024-09-03T18:53:02.808Z",
    },
    {
      _id: "66d72401c7d0285f960ae3f2",
      State: "West Bengal",
      Constituency: "Srerampur",
      createdAt: "2024-09-03T18:53:02.808Z",
    },
    {
      _id: "66d72401c7d0285f960ae3f3",
      State: "West Bengal",
      Constituency: "Hooghly",
      createdAt: "2024-09-03T18:53:02.808Z",
    },
    {
      _id: "66d72401c7d0285f960ae3f4",
      State: "West Bengal",
      Constituency: "Arambagh",
      createdAt: "2024-09-03T18:53:02.808Z",
    },
    {
      _id: "66d72401c7d0285f960ae3f5",
      State: "West Bengal",
      Constituency: "Tamluk",
      createdAt: "2024-09-03T18:53:02.808Z",
    },
    {
      _id: "66d72401c7d0285f960ae3f6",
      State: "West Bengal",
      Constituency: "Kanthi",
      createdAt: "2024-09-03T18:53:02.808Z",
    },
    {
      _id: "66d72401c7d0285f960ae3f7",
      State: "West Bengal",
      Constituency: "Ghatal",
      createdAt: "2024-09-03T18:53:02.808Z",
    },
    {
      _id: "66d72401c7d0285f960ae3f8",
      State: "West Bengal",
      Constituency: "Jhargram",
      createdAt: "2024-09-03T18:53:02.808Z",
    },
    {
      _id: "66d72401c7d0285f960ae3f9",
      State: "West Bengal",
      Constituency: "Medinipur",
      createdAt: "2024-09-03T18:53:02.808Z",
    },
    {
      _id: "66d72401c7d0285f960ae3fa",
      State: "West Bengal",
      Constituency: "Purulia",
      createdAt: "2024-09-03T18:53:02.808Z",
    },
    {
      _id: "66d72401c7d0285f960ae3fb",
      State: "West Bengal",
      Constituency: "Bankura",
      createdAt: "2024-09-03T18:53:02.808Z",
    },
    {
      _id: "66d72401c7d0285f960ae3fc",
      State: "West Bengal",
      Constituency: "Bishnupur",
      createdAt: "2024-09-03T18:53:02.808Z",
    },
    {
      _id: "66d72401c7d0285f960ae3fd",
      State: "West Bengal",
      Constituency: "Bardhaman Purba",
      createdAt: "2024-09-03T18:53:02.810Z",
    },
    {
      _id: "66d72401c7d0285f960ae3fe",
      State: "West Bengal",
      Constituency: "Burdwan - Durgapur",
      createdAt: "2024-09-03T18:53:02.810Z",
    },
    {
      _id: "66d72401c7d0285f960ae3ff",
      State: "West Bengal",
      Constituency: "Asansol",
      createdAt: "2024-09-03T18:53:02.810Z",
    },
    {
      _id: "66d72401c7d0285f960ae400",
      State: "West Bengal",
      Constituency: "Bolpur",
      createdAt: "2024-09-03T18:53:02.810Z",
    },
    {
      _id: "66d72401c7d0285f960ae401",
      State: "West Bengal",
      Constituency: "Birbhum",
      createdAt: "2024-09-03T18:53:02.810Z",
    },
    {
      _id: "66d72401c7d0285f960ae402",
      State: "Jammu & Kashmir",
      Constituency: "Baramulla",
      createdAt: "2024-09-03T18:53:02.810Z",
    },
    {
      _id: "66d72401c7d0285f960ae403",
      State: "Jammu & Kashmir",
      Constituency: "Srinagar",
      createdAt: "2024-09-03T18:53:02.810Z",
    },
    {
      _id: "66d72401c7d0285f960ae404",
      State: "Jammu & Kashmir",
      Constituency: "Anantnag-Rajouri",
      createdAt: "2024-09-03T18:53:02.810Z",
    },
    {
      _id: "66d72401c7d0285f960ae405",
      State: "Jammu & Kashmir",
      Constituency: "Udhampur",
      createdAt: "2024-09-03T18:53:02.810Z",
    },
    {
      _id: "66d72401c7d0285f960ae406",
      State: "Jammu & Kashmir",
      Constituency: "Jammu",
      createdAt: "2024-09-03T18:53:02.810Z",
    },
  ];
  const filterLoksabhaSeat = () => {
    const arr = data.filter((e) => e.State === prop);
    setLoksabhaseat(arr);
  };

  useEffect(() => {
    filterLoksabhaSeat();
  }, [prop]);

  const validate = Yup.object({
    member_name: Yup.string().required("Enter Member Name."),
    member_contact_number: Yup.string()
      .required("Enter Member Contact Number.")
      .matches(
        /^(\+\d{1,3}[- ]?)?\d{10}$/,
        "Phone number is not valid. It should be 10 digits long."
      ),
    new_member_id: Yup.string().required("Enter New Member Id."),
    religion: Yup.string().required("Enter religion."),
    loksabha_name: Yup.string().required("Select Loksabha Name."),
    primary_member_name: Yup.string().required("Enter Primary member name."),
    primary_contact_number: Yup.string()
    .required("Enter Primary Contact Number.")
    .matches(
        /^(\+\d{1,3}[- ]?)?\d{10}$/,
        "Phone number is not valid. It should be 10 digits long."
    ),
    primary_member_id: Yup.string().required("Enter Primary Member Id."),
});

const handleSubmit = async (values, { resetForm }) => {
  setLoading(true); // Start loading
  try {
    const formdata = new FormData();

    for (const key in values) {
      formdata.append(key, values[key]);
    }

    // Create both fetch requests concurrently
    const postFormData = fetch(
      "https://script.google.com/macros/s/AKfycbyPGj76n_PHqQmYSGP98YwQ-lMCuCpcGeRWsadwLpwMRgX0lFlJN5EcW6B9_kaJF_WP/exec",
      {
        method: "POST",
        body: formdata,
        mode: "no-cors",
      }
    );

    const postJsonData = fetch("https://varahe-morcha.onrender.com/add", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    });

    // Execute both fetch requests concurrently
    await Promise.all([postFormData]);

    // Reset form and state after successful submission
    resetForm();
    setLoksabha("");
    setState("");
    setSelectedReligion("");
    toast.success("Your data submitted");
  } catch (error) {
    toast.error("Failed to submit data. Please try again.");
  } finally {
    setLoading(false); // End loading
  }
};

  const handleLoksabhaChange = (event) => {
    setLoksabha(event.target.value);
  };

  const handleReligionChange = (event) => {
    setSelectedReligion(event.target.value);
  };

  return (
    <Formik
      initialValues={{
        member_name: "",
        member_contact_number: "",
        primary_member_id: "",
        new_member_id: "",
        religion: "",
        loksabha_name: "",
        primary_member_name: "",
        primary_contact_number: "",
      }}
      validationSchema={validate}
      onSubmit={handleSubmit}
    //   onSubmit = {(val)=>console.log(val)}
      enableReinitialize
    >
      {(formik) => (
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "100%", bgcolor: "white" },
            padding: { xs: "10px", sm: "20px" },
            bgcolor: "#f5f5f5",
            borderRadius: "8px",
          }}
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Member Information Form
            </h5>
          </div>

          <div className="modal-body">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <InputLabel id="loksabha-select-label">
                  Loksabha Name
                </InputLabel>
                <Select
                  fullWidth
                  labelId="loksabha-select-label"
                  id="loksabha-select"
                  value={formik.values.loksabha_name}
                  sx={{ bgcolor: "white" }}
                  label="Loksabha Name"
                  name="loksabha_name"
                  onChange={(event) => {
                    handleLoksabhaChange(event);
                    formik.handleChange(event);
                  }}
                  error={
                    formik.touched.loksabha_name &&
                    Boolean(formik.errors.loksabha_name)
                  }
                >
                  {loksabhaseat.map((e, i) => (
                    <MenuItem key={i} value={e.Constituency}>
                      {e.Constituency}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.loksabha_name && formik.errors.loksabha_name && (
                  <div
                    style={{
                      color: "#D33F50",
                      fontSize: "14px",
                      background: "white",
                      padding: "5px",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.loksabha_name}
                  </div>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel id="religion-select-label">
                  Religion of New Member
                </InputLabel>
                <Select
                  fullWidth
                  labelId="religion-select-label"
                  id="religion-select"
                  value={formik.values.religion}
                  sx={{ bgcolor: "white" }}
                  label="Religion"
                  name="religion"
                  onChange={(event) => {
                    handleReligionChange(event);
                    formik.handleChange(event);
                  }}
                  error={
                    formik.touched.religion &&
                    Boolean(formik.errors.religion)
                  }
                >
                  {religion.map((e, i) => (
                    <MenuItem key={i} value={e.religionName}>
                      {e.religionName}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.religion && formik.errors.religion && (
                  <div
                    style={{
                      color: "#D33F50",
                      fontSize: "14px",
                      background: "white",
                      padding: "5px",
                      textAlign: "left",
                    }}
                  >
                    {formik.errors.religion}
                  </div>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="New Member Name"
                  name="member_name"
                  placeholder="New Member Name"
                  onChange={formik.handleChange}
                  value={formik.values.member_name}
                  error={
                    formik.touched.member_name &&
                    Boolean(formik.errors.member_name)
                  }
                  helperText={
                    formik.touched.member_name && formik.errors.member_name
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="New Member Contact Number"
                  name="member_contact_number"
                  placeholder="New Member Contact Number"
                  onChange={formik.handleChange}
                  value={formik.values.member_contact_number}
                  error={
                    formik.touched.member_contact_number &&
                    Boolean(formik.errors.member_contact_number)
                  }
                  helperText={
                    formik.touched.member_contact_number &&
                    formik.errors.member_contact_number
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="New Member ID"
                  name="new_member_id"
                  placeholder="New Member ID"
                  onChange={formik.handleChange}
                  value={formik.values.new_member_id}
                  error={
                    formik.touched.new_member_id &&
                    Boolean(formik.errors.new_member_id)
                  }
                  helperText={
                    formik.touched.new_member_id && formik.errors.new_member_id
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Primary Member Name"
                  name="primary_member_name"
                  placeholder="Primary Member Name"
                  onChange={formik.handleChange}
                  value={formik.values.primary_member_name}
                  error={
                    formik.touched.primary_member_name &&
                    Boolean(formik.errors.primary_member_name)
                  }
                  helperText={
                    formik.touched.primary_member_name && formik.errors.primary_member_name
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Primary Member Contact Number"
                  name="primary_contact_number"
                  placeholder="Primary Member Contact Number"
                  onChange={formik.handleChange}
                  value={formik.values.primary_contact_number}
                  error={
                    formik.touched.primary_contact_number &&
                    Boolean(formik.errors.primary_contact_number)
                  }
                  helperText={
                    formik.touched.primary_contact_number &&
                    formik.errors.primary_contact_number
                  }
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Primary Member ID"
                  name="primary_member_id"
                  placeholder="Primary Member ID"
                  onChange={formik.handleChange}
                  value={formik.values.primary_member_id}
                  error={
                    formik.touched.primary_member_id &&
                    Boolean(formik.errors.primary_member_id)
                  }
                  helperText={
                    formik.touched.primary_member_id && formik.errors.primary_member_id
                  }
                />
              </Grid>
            </Grid>
          </div>

          <div
            className="modal-footer"
            style={{
              textAlign: { xs: "center", sm: "right" },
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              color="success"
              type="submit"
              disabled={loading} // Disable button when loading
            >
              {loading ? "Submitting..." : "Submit"}
            </Button>
          </div>
        </Box>
      )}
    </Formik>
  );
};

export default AsForm;